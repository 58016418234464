import * as tasksActions from './Tasks.action.js';
import { convertToLocalTime } from '../../Utils.js';

export default {
    mapStateToProps: (state) => {
        return {
            taskTypes: state.TasksReducer.newTaskTypes,
            taskType: state.TasksReducer.taskType,
            tasks: state.TasksReducer.tasks?.map(t => {
                return (
                    {
                        ...t,
                        dueDate: new Date(t.dueDate),
                        creationDate: convertToLocalTime(new Date(t.creationDate))
                    });
            }),
            task: state.TasksReducer.task ? {
                ...state.TasksReducer.task,
                dueDate: new Date(state.TasksReducer.task.dueDate),
                creationDate: convertToLocalTime(new Date(state.TasksReducer.task.creationDate))
            } : null,
            isFollow: state.TasksReducer.isFollow,
            history: state.TasksReducer.history?.map(h => {
                return (
                    {
                        ...h,
                        updateDate: convertToLocalTime(new Date(h.updateDate))
                    });
            }),
            attachments: state.TasksReducer.attachments?.map(a => {
                return (
                    {
                        ...a,
                        uploadDate: convertToLocalTime(new Date(a.uploadDate))
                    });
            }),
            attachmentLink: state.TasksReducer.attachmentLink,
            allUsers: state.TasksReducer.allUsers,
            usersByMinPrivilege: state.TasksReducer.usersByMinPrivilege
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            requestGetNewTaskTypes: () => dispatch(tasksActions.requestGetNewTaskTypes()),
            requestGetTaskType: (id, onSuccess) => dispatch(tasksActions.requestGetTaskType(id, onSuccess)),
            requestGetTasks: (params, onSuccess) => dispatch(tasksActions.requestGetTasks(params, onSuccess)),
            requestGetAllUsers: (onSuccess) => dispatch(tasksActions.requestGetAllUsers(onSuccess)),
            requestGetUsersByMinPrivilege: (minPrivilege, onSuccess) => dispatch(tasksActions.requestGetUsersByMinPrivilege(minPrivilege, onSuccess)),
            requestSetTaskStatus: (statusUpdate, onSuccess, onFailure) => dispatch(tasksActions.requestSetTaskStatus(statusUpdate, onSuccess, onFailure)),
            requestGetTask: (id, onSuccess) => dispatch(tasksActions.requestGetTask(id, onSuccess)),
            requestRemoveTask: (id, onSuccess) => dispatch(tasksActions.requestRemoveTask(id, onSuccess)),
            requestCreateTask: (task, onSuccess, onFailure) => dispatch(tasksActions.requestCreateTask(task, onSuccess, onFailure)),
            requestUpdateTask: (task, onSuccess, onFailure) => dispatch(tasksActions.requestUpdateTask(task, onSuccess, onFailure)),
            requestGetTaskIsFollow: (id) => dispatch(tasksActions.requestGetTaskIsFollow(id)),
            requestSetTaskFollow: (id) => dispatch(tasksActions.requestSetTaskFollow(id)),
            requestSetTaskUnfollow: (id) => dispatch(tasksActions.requestSetTaskUnfollow(id)),
            requestGetTaskHistory: (taskId, onSuccess) => dispatch(tasksActions.requestGetTaskHistory(taskId, onSuccess)),
            requestGetTaskAttachments: (taskId, onSuccess) => dispatch(tasksActions.requestGetTaskAttachments(taskId, onSuccess)),
            requestGetTaskAttachmentLink: (attachmentId, onSuccess) => dispatch(tasksActions.requestGetTaskAttachmentLink(attachmentId, onSuccess)),
            requestRemoveTaskAttachment: (attachmentId, onSuccess) => dispatch(tasksActions.requestRemoveTaskAttachment(attachmentId, onSuccess)),
            requestAddTaskAttachment: (taskId, file, onSuccess) => dispatch(tasksActions.requestAddTaskAttachment(taskId, file, onSuccess)),
            clearTask: () => dispatch(tasksActions.clearTask()),
            clearAttachmentLink: () => dispatch(tasksActions.clearAttachmentLink()),
        };
    }
};
