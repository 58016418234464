import React, { useState, useEffect, useRef } from "react";
import styles from "./TaskList.module.css";
import sceneStyles from "../Scenes.module.css";
import { connect } from 'react-redux';
import connector from './Tasks.connector.js';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DateInput, DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Input } from "@progress/kendo-react-inputs";
import { TaskBoard, TaskBoardToolbar } from "@progress/kendo-react-taskboard";
import { stateCategories } from "../../Enums";
import { Card } from "@progress/kendo-react-layout";
import { convertToLocalTime, debounce, convertToDashedDateString } from '../../Utils';
import { useSearchParams } from 'react-router-dom';
import { Button } from "@progress/kendo-react-buttons";
import { Popup } from "@progress/kendo-react-popup";
import { ListView } from "@progress/kendo-react-listview";
import { TaskWindow } from "./TaskWindow";

const columns = Object.entries(stateCategories).map((entry, i) => ({
    id: i,
    status: entry[0],
    title: entry[1]
}));

const defaultUserListItem = { id: 0, name: "Válassz felhasználót" };

const Tasks = (props) => {
    const {
        requestGetTaskType,
        requestGetNewTaskTypes,
        requestGetTasks,
        requestGetAllUsers,
        requestGetUsersByMinPrivilege,
        requestSetTaskStatus,
        requestGetTask,
        requestRemoveTask,
        requestCreateTask,
        requestUpdateTask,
        requestGetTaskIsFollow,
        requestSetTaskFollow,
        requestSetTaskUnfollow,
        requestGetTaskHistory,
        requestGetTaskAttachments,
        requestGetTaskAttachmentLink,
        requestRemoveTaskAttachment,
        requestAddTaskAttachment,
        clearTask,
        clearAttachmentLink,
        taskTypes,
        taskType,
        tasks,
        task,
        isFollow,
        history,
        attachments,
        attachmentLink,
        allUsers,
        usersByMinPrivilege
    } = props;

    const [taskData, setTaskData] = useState([]);

    const [currentSearchText, setCurrentSearchText] = useState("");
    const [searchFields, setSearchFields] = useState();
    const [searchParams, setSearchParams] = useSearchParams();

    const [showPopup, setShowPopup] = useState(false);
    const [taskWindowData, setTaskWindowData] = useState({ visible: false });

    const anchor = useRef(null);

    const debouncedSetSearchFields = debounce((newSearchFields) => setSearchFields(newSearchFields), 600);

    useEffect(() => {
        const taskId = searchParams.get("taskId");
        if (taskId) {
            requestGetTask(taskId, () => showEditWindow(taskId));
            return;
        }

        let newSearchFields = { text: searchParams.get("searchText") ?? "" };
        setCurrentSearchText(searchParams.get("searchText") ?? "");
        if (searchParams.get("searchDateRangeFrom") && searchParams.get("searchDateRangeTo"))
            newSearchFields.dateRange = { start: new Date(searchParams.get("searchDateRangeFrom")), end: new Date(searchParams.get("searchDateRangeTo")) };

        setSearchFields(newSearchFields);
    }, []);

    useEffect(() => {
        if (requestGetTasks && searchFields?.user)
            listTasksByFilters();
    }, [searchFields]);

    useEffect(() => {
        requestGetNewTaskTypes();
    }, [requestGetNewTaskTypes]);

    const listTasksByFilters = () => {
        let params = new URLSearchParams();
        if (searchFields.text && searchFields.text !== "") {
            params.append("searchText", searchFields.text);
        }
        if (searchFields.user?.id) {
            params.append("searchUserId", searchFields.user.id.toString());
        }
        if (searchFields.dateRange?.start && searchFields.dateRange?.end) {
            params.append("searchDateRangeFrom", convertToDashedDateString(searchFields.dateRange.start));
            params.append("searchDateRangeTo", convertToDashedDateString(searchFields.dateRange.end));
        }
        if (searchParams.has('taskId'))
            params.append('taskId', searchParams.get('taskId'));

        // Would be better in a useEffect hook, but should not run for every store change
        requestGetTasks(params,
            (tasks) => {
                setTaskData(tasks.map(t => ({
                    ...t,
                    dueDate: new Date(t.dueDate),
                    creationDate: convertToLocalTime(new Date(t.creationDate)),
                    status: t.type.states.find(s => s.id === t.statusId).category,
                    // Priority field is needed for task board
                    priority: {
                        color: t.type.color
                    },
                })));
            });
        setSearchParams(params);
    };

    useEffect(() => {
        if (allUsers) {
            const user = allUsers.find(u => u.id === parseInt(searchParams.get("searchUserId")));
            setSearchFields({ ...searchFields, user: user ?? defaultUserListItem });
        }
    }, [allUsers]);

    useEffect(() => {
        requestGetAllUsers();
    }, [requestGetAllUsers]);

    const renderColumn = (props) => {
        return <div
            ref={props.elementRef}
            className={`k-taskboard-column ${styles.categoryColumn}`}
            style={{ ...props.style, width: `${(75 / columns.length)}vw` }}
            data-taskboard-type="column"
            data-taskboard-id={props.column.id}
        >
            <fieldset style={{ height: "100%" }}>
                <legend>{props.column.title}</legend>
                <div className={styles.cardList}>{props.children}</div>
            </fieldset>
        </div>;
    };

    // Task status change via dropdown list, only move card on successful requests
    const onManualStateChange = (e, task) => {
        task.disabled = true;
        const taskIdx = taskData.findIndex(i => i.id === task.id);
        const setStatusRequest = { cardId: task.id, newStatusId: e.value.id, rowVersion: task.rowVersion };
        requestSetTaskStatus(setStatusRequest,
            (rowVersion) => {
                task.rowVersion = rowVersion;
                task.status = e.value.category;
                task.statusId = e.value.id;
                task.disabled = false;
                setTaskData([...taskData.slice(0, taskIdx), task, ...taskData.slice(taskIdx + 1)]);
            },
            () => {
                task.disabled = false;
                setTaskData([...taskData.slice(0, taskIdx), task, ...taskData.slice(taskIdx + 1)]);
            });
        // rerender to show disabled card
        setTaskData([...taskData.slice(0, taskIdx), task, ...taskData.slice(taskIdx + 1)]);
    };

    const showEditWindow = (taskId) => {
        requestGetTaskIsFollow(taskId);
        requestGetTaskHistory(taskId);
        requestGetTaskAttachments(taskId);
        setTaskWindowData({ visible: true, isNewTask: false });
        searchParams.set('taskId', taskId.toString());
        setSearchParams(searchParams);
    };

    const handleEditTask = (taskId => {
        requestGetTask(taskId, () => showEditWindow(taskId));
    });

    const renderCard = (props) => {
        const { task } = props;
        return (
            <Card
                key={task.id}
                ref={props.elementRef}
                data-taskboard-type="task"
                data-taskboard-id={task.id}
                tabIndex={props.tabIndex}
                style={{ ...props.style, borderLeftWidth: "8px" }}
                className={`k-taskboard-card k-cursor-move k-taskboard-card-category ${task.disabled ? "k-disabled" : ""}`}
            >
                <div className={styles.card}>
                    <span className={styles.cardTitle} onClick={() => handleEditTask(task.id)}>
                        {task.title}
                    </span>
                    <div className={styles.cardData}>
                        <div>
                            <i className={`fa-regular fa-hashtag ${styles.cardDataIcon}`}></i>
                            <span className={styles.cardDataText}>{`${task.id} ${task.type.name} (${task.type.group})`}</span>
                        </div>
                        <div>
                            <i className={`fa-regular fa-user ${styles.cardDataIcon}`}></i>
                            <span className={styles.cardDataText}>{`${task.assigneesNames.join(", ")}`}</span>
                        </div>
                        <div>
                            <i className={`fa-regular fa-calendar ${styles.cardDataIcon}`}></i>
                            <span className={styles.cardDataText}>{`${task.creationDate.toLocaleDateString("hu")}`}</span>
                            <span style={(new Date() > task.dueDate.getTime()) ? { color: "red" } : {}}>
                                <i className={`fa-regular fa-clock ${styles.cardDataIcon}`}></i>
                                <span className={styles.cardDataText}>{`${task.dueDate.toLocaleDateString("hu")}`}</span>
                            </span>
                        </div>
                        <DropDownList
                            data={task.type.states}
                            textField="name"
                            value={task.type.states.find(state => state.id === task.statusId)}
                            onChange={(e) => onManualStateChange(e, task)}
                        />
                    </div>
                </div>
            </Card>);
    };

    // Handling task card drags
    const handleTaskBoardChange = ({ data, item, previousItem, type: eventType }) => {
        if (eventType !== "task")
            return;

        // If status changed, if request fails put the card back
        if (item.status !== previousItem.status) {
            const newState = item.type.states.find(state => state.isPrimary && state.category === item.status);
            if (!newState)
                return;
            const setStatusRequest = { cardId: item.id, newStatusId: newState.id, rowVersion: item.rowVersion };
            item.disabled = true;
            requestSetTaskStatus(setStatusRequest,
                (rowVersion) => {
                    item.rowVersion = rowVersion;
                    item.disabled = false;
                    // Spread to rerender
                    setTaskData([...data]);
                },
                () => {
                    item.status = previousItem.status;
                    item.statusId = previousItem.statusId;
                    item.disabled = false;
                    const idx = taskData.findIndex(i => i.id === item.id);
                    setTaskData([...taskData.slice(0, idx), item, ...taskData.slice(idx + 1)]);
                });
            item.statusId = newState.id;
        }
        setTaskData(data);
    };

    const selectedUserRender = (element, value) => {
        if (value !== defaultUserListItem) {
            return (<span className="k-input-inner" onMouseDown={(e) => { e.preventDefault(); }}>
                <span className="k-input-value-text">{value.name}</span>
                <span className="k-clear-value" style={{ height: "16px", width: "16px" }}>
                    <span className="k-icon k-i-x" onClick={(e) => {
                        e.stopPropagation();
                        setSearchFields({ ...searchFields, user: defaultUserListItem });
                    }} />
                </span>
            </span>);
        }
        return element;
    };

    const handleCreateNewTask = (id) => {
        requestGetTaskType(id);
        setTaskWindowData({ visible: true, isNewTask: true });
    };

    const renderTaskTypeItem = (props) => {
        return (
            <li
                className={`k-list-item ${styles.popupListItem}`}
                onClick={(e) => {
                    handleCreateNewTask(props.dataItem.typeId);
                    setShowPopup(false);
                }}
                onMouseDown={(e) => { e.preventDefault(); }} // Prevent popup buttons onBlur event
            >
                <span className="k-list-item-text">
                    {props.dataItem.name}
                </span>
            </li>
        );
    };

    const handleCloseWindow = (refreshTasks) => {
        setTaskWindowData({ visible: false });
        searchParams.delete('taskId');
        setSearchParams(searchParams);
        clearTask();
        if (refreshTasks) {
            listTasksByFilters();
        }
    };

    return (
        <div className={sceneStyles.container}>
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
            />
            <TaskBoard
                className={styles.taskBoard}
                columnData={columns}
                taskData={taskData}
                column={renderColumn}
                card={renderCard}
                onChange={handleTaskBoardChange}
            >
                <TaskBoardToolbar>
                    <div className={styles.taskBoardHeader}>
                        <div className={styles.taskBoardActions}>
                            <Input
                                label="Keresés"
                                className={styles.textSearchField}
                                value={currentSearchText}
                                onChange={(e) => {
                                    setCurrentSearchText(e.value);
                                    debouncedSetSearchFields({ ...searchFields, text: e.value });
                                }}
                            />
                            <DropDownList
                                className={styles.userSearch}
                                data={allUsers ?? []}
                                textField="name"
                                value={searchFields?.user}
                                defaultItem={defaultUserListItem}
                                valueRender={selectedUserRender}
                                onChange={(e) => setSearchFields({ ...searchFields, user: e.value })}
                            />
                            <DateRangePicker
                                className={styles.dateRangePicker}
                                startDateInput={props => (<DateInput {...props} label={"Határidő szűrés"} />)}
                                endDateInput={props => (<DateInput {...props} label={""} />)}
                                value={searchFields?.dateRange}
                                onChange={(e) => setSearchFields({ ...searchFields, dateRange: e.value })}
                            />
                            <div>
                                <Button ref={anchor} fillMode="flat" iconClass="fa-solid fa-xl fa-plus" themeColor="primary" disabled={taskTypes.length === 0}
                                    onClick={() => setShowPopup(!showPopup)}
                                    onBlur={() => setShowPopup(false)}
                                />
                                <Popup
                                    anchor={anchor?.current?.element}
                                    anchorAlign={{ horizontal: "right", vertical: "bottom" }}
                                    popupAlign={{ horizontal: "right", vertical: "top" }}
                                    show={showPopup}
                                >
                                    <ListView className={styles.popupList} data={taskTypes} item={renderTaskTypeItem} />
                                </Popup>
                            </div>
                        </div>
                    </div>
                </TaskBoardToolbar>
            </TaskBoard>
            {taskWindowData.visible && taskType && (taskWindowData.isNewTask || task) && allUsers ? (
                <TaskWindow
                    {...taskWindowData}
                    usersByMinPrivilege={usersByMinPrivilege}
                    allUsers={allUsers}
                    taskType={taskType}
                    task={task}
                    isFollow={isFollow}
                    history={history}
                    attachments={attachments}
                    attachmentLink={attachmentLink}
                    requestGetUsersByMinPrivilege={requestGetUsersByMinPrivilege}
                    requestGetTaskType={requestGetTaskType}
                    requestRemoveTask={requestRemoveTask}
                    requestCreateTask={requestCreateTask}
                    requestUpdateTask={requestUpdateTask}
                    requestSetTaskFollow={requestSetTaskFollow}
                    requestSetTaskUnfollow={requestSetTaskUnfollow}
                    requestGetTaskHistory={requestGetTaskHistory}
                    requestGetTaskAttachmentLink={requestGetTaskAttachmentLink}
                    requestGetTaskAttachments={requestGetTaskAttachments}
                    requestRemoveTaskAttachment={requestRemoveTaskAttachment}
                    requestAddTaskAttachment={requestAddTaskAttachment}
                    clearAttachmentLink={clearAttachmentLink}
                    handleCloseWindow={handleCloseWindow}
                    handleUpdateWindow={(task) => {
                        showEditWindow(task.id);
                    }}
                />
            ) : null}
        </div>
    );
};

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Tasks);