import React, { useState, useEffect, Fragment } from "react";
import styles from "./TaskWindow.module.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

export const History = (props) => {
    const {
        creatorName,
        creationDate,
        isNewTask,
        history
    } = props;

    const [historyData, setHistoryData] = useState([]);

    useEffect(() => {
        if (isNewTask)
            return;
        if (history) {
            const creationRow = { user: creatorName, updateDate: creationDate, fieldName: "Feladat létrehozva", oldValue: "", newValue: "" };
            setHistoryData([
                history.map(historyRow => {
                    if (historyRow.attachmentChange) {
                        return { user: historyRow.user, updateDate: historyRow.updateDate, fieldName: "Fájl", oldValue: historyRow.attachmentChange.type === 'Attach' ? "Csatolás" : "Törlés", newValue: historyRow.attachmentChange.fileName };
                    } else {
                        return historyRow.fieldChanges.map(field => ({ user: historyRow.user, updateDate: historyRow.updateDate, fieldName: field.fieldName, oldValue: field.oldValue ?? '', newValue: field.newValue ?? '' }));
                    }
                }),
                creationRow
            ].flat(2)
            );
        }
    }, [history]);

    return (
        <Fragment>
            {isNewTask ? (
                <div className={styles.emptyTab}>
                    Hozza létre a feladatot, hogy legyen állapot történet
                </div>) : (
                <Grid
                    data={historyData}
                    scrollable='none'
                >
                    <GridColumn field="user" width={180} title="Módosító" className={styles.nameColumn} />
                    <GridColumn field="updateDate" format="{0:yyyy.MM.dd. HH:mm:ss}" width={210} title="Módosítás dátuma" className={styles.dateColumn} />
                    <GridColumn field="fieldName" title="Mező" className={styles.fieldNameColumn} />
                    <GridColumn field="oldValue" title="Régi érték" className={styles.fieldValueColumn} />
                    <GridColumn field="newValue" title="Új érték" className={styles.fieldValueColumn} />
                </Grid>
            )}
        </Fragment>
    );
};