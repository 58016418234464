import * as actionTypes from "./Auth.action.types";

export const initialState = {
    user: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_GET_USER: {
            return {
                ...state,
                user: action.payload.data
            };
        }
        default:
            return state;
    }
}
