import React, { useState, useEffect, useRef, Fragment } from "react";
import styles from "./TaskWindow.module.css";
import sceneStyles from "../Scenes.module.css";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Typography } from "@progress/kendo-react-common";
import { formatNumber } from '@progress/kendo-intl';


export const Attachments = (props) => {
    const {
        isNewTask,
        task,
        attachments,
        attachmentLink,
        requestGetTaskAttachmentLink,
        requestGetTaskAttachments,
        requestGetTaskHistory,
        requestRemoveTaskAttachment,
        requestAddTaskAttachment,
        clearAttachmentLink,
    } = props;

    const [fileRemoveDialogData, setFileRemoveDialogData] = useState({ visible: false });

    const fileInput = useRef(null);

    useEffect(() => {
        if (attachmentLink) {
            // Create anchor element 
            let anchorElement = document.createElement('a');
            anchorElement.href = attachmentLink;
            document.body.appendChild(anchorElement); // Append element to DOM
            anchorElement.click(); // Click on link
            anchorElement.remove();  // Remove element   
            clearAttachmentLink();
        }

    }, [attachmentLink]);


    const handleFileUpload = (e) => {
        if (e.target.files[0]) {
            requestAddTaskAttachment(task.id, e.target.files[0], () => {
                requestGetTaskHistory(task.id);
                requestGetTaskAttachments(task.id);
            });
            e.target.value = null;
        }
    };

    const renderRemoveCell = (props) => {
        return (
            <td>
                <Button fillMode="flat" iconClass="fa-solid fa-trash" onClick={() => setFileRemoveDialogData({ visible: true, attachment: props.dataItem })} themeColor="primary" />
            </td>);
    };

    const renderFileNameCell = (props) => {
        return (<td>
            <a href="/"
                draggable={false} onClick={e => {
                    e.preventDefault();
                    requestGetTaskAttachmentLink(props.dataItem.id);
                }}>{props.dataItem[props.field]} </a>
        </td>);
    };

    const renderFileSizeCell = (props) => {
        let value = props.dataItem[props.field];
        let unit = 'bájt';

        if (value > 1024 * 1024) {
            value = (value / (1024 * 1024));
            unit = 'MB';
        } else if (value > 1024) {
            value = (value / 1024);
            unit = 'kB';
        }

        return (
            <td>{formatNumber(value, unit === 'bájt' ? 'n' : 'n2', 'hu')} {unit}</td>);
    };

    const closeFileRemoveDialog = () => setFileRemoveDialogData({ visible: false });

    return (
        <Fragment>
            {isNewTask ? (
                <div className={styles.emptyTab}>
                    Hozza létre a feladatot, hogy tudjon fájlokat csatolni
                </div>) : (
                <div className={styles.attachmentContainer}>
                    <Button
                        themeColor={"primary"}
                        className={styles.attachFileButton}
                        onClick={() => fileInput.current.click()}
                        iconClass="fa-solid fa-plus"
                    >
                        Fájl csatolása
                    </Button>
                    <Grid
                        data={attachments}
                        scrollable="none"
                    >
                        <GridColumn field="fileName" title="Fájl neve" cell={renderFileNameCell} />
                        <GridColumn field="fileSize" title="Fájl mérete" width={120} cell={renderFileSizeCell} />
                        <GridColumn field="uploaderName" title="Feltöltő" className={styles.nameColumn} />
                        <GridColumn field="uploadDate" format="{0:yyyy.MM.dd. HH:mm:ss}" title="Feltöltés dátuma" className={styles.dateColumn} />
                        <GridColumn width="50px" cell={renderRemoveCell} />
                    </Grid>
                    <input type="file" ref={fileInput} onChange={handleFileUpload} hidden />
                    {fileRemoveDialogData.visible && (
                        <Dialog
                            width={400}
                            title={"Megerősítés"}
                            onClose={closeFileRemoveDialog}
                            autoFocus={true}
                        >
                            <Typography.p>{`Biztosan törli a(z) ${fileRemoveDialogData.attachment.fileName} fájlt?`} </Typography.p>
                            <DialogActionsBar>
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        onClick={closeFileRemoveDialog}
                                    >
                                        Nem
                                    </Button>
                                    <Button
                                        themeColor="primary"
                                        onClick={() => {
                                            requestRemoveTaskAttachment(fileRemoveDialogData.attachment.id, () => {
                                                requestGetTaskHistory(task.id);
                                                requestGetTaskAttachments(task.id);
                                            });
                                            closeFileRemoveDialog();
                                        }}
                                    >
                                        Igen
                                    </Button>
                                </div>
                            </DialogActionsBar>
                        </Dialog>
                    )}
                </div>
            )}
        </Fragment>
    );
};