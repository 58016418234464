import React, { useMemo } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import styles from "./Login.module.css";
import SideBar from "../sidebar/SideBar";
import { Typography } from "@progress/kendo-react-common";
import logoShort from '../../assets/sp_logo_short.png';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const Login = (props) => {
    const signInPage = useMemo(() => {
        return (
            <div className={styles.container}>
                <div className={styles.loginContainer}>
                    <img className={styles.logo} width="170px" src={logoShort} alt="logo"></img>
                    <Typography.p className={styles.backOffice}>Back Office</Typography.p>
                    <SignInButton className={styles.signInButton} />
                </div>
                <Typography.p className={styles.cet}>CET Solutions Kft. | 2023</Typography.p>
            </div>
        );
    }, []);

    return (
        <>
            <AuthenticatedTemplate>
                <SideBar />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {signInPage}
            </UnauthenticatedTemplate>
        </>
    );
};