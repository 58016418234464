import * as actionTypes from "./Response.action.types";

export const initialState = {
    errors: [],
    successes: [],
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.PUSH_ERROR: {
            return {
                ...state,
                errors: [...state.errors, action.payload]
            };
        }
        case actionTypes.POP_ERROR: {
            return {
                ...state,
                errors: state.errors.filter(e => e.id !== action.payload.id)
            };
        }
        case actionTypes.PUSH_SUCCESS: {
            return {
                ...state,
                successes: [...state.successes, action.payload]
            };
        }
        case actionTypes.POP_SUCCESS: {
            return {
                ...state,
                successes: state.successes.filter(e => e.id !== action.payload.id)
            };
        }
        case actionTypes.SET_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case actionTypes.CLEAR_LOADING: {
            return {
                ...state,
                loading: false
            };
        }
        default:
            return state;
    }
}
