import axios from 'axios';
import { severityLevel } from '../Enums';
import * as errorTypes from '../errorTypes';
import { PublicClientApplication } from '@azure/msal-browser';
import * as actionTypes from './Response.action.types.js';


const successMessageTimeoutMs = 3000;

function createError(errorType, data, message) {
    return { id: Date.now(), type: errorType, data, message };
}

function createSuccess(message) {
    return { id: Date.now(), message };
}

async function getToken(msalConfig){
    const instance = new PublicClientApplication(msalConfig);
    const account = instance.getAllAccounts()[0];
    const forceRefresh = (new Date(account.idTokenClaims.exp * 1000) < new Date());

    const tokenResponse = await instance.acquireTokenSilent({ account: account, forceRefresh: forceRefresh });

    return tokenResponse.accessToken;
}

// request: {method, endpoint, data}
export default async function apiCall(dispatch, getState, request, callbacks) {
    let error = null;
    let token = null;
    const config = getState().ConfigReducer.config;
    const appInsights = config.appInsights;

    try {
        appInsights.trackTrace({ severityLevel: severityLevel.Information, message: "Acquiring access token" });
        token = await getToken(config.msalConfig);
    } catch (e) {
        appInsights.trackTrace({ severityLevel: severityLevel.Error, message: "Acquiring access token failed" });
        appInsights.trackException({ exception: e, severityLevel: severityLevel.Error });
        error = createError(errorTypes.MSAL_ERROR, e, "Autentikációs hiba");
        dispatch({ type: actionTypes.PUSH_ERROR, payload: error });
        console.error(e);
        throw error;
    }

    if (token) {
        try {
            const bearer = `Bearer ${token}`;
            const headers = { Authorization: bearer };

            const axiosRequestConfig = {
                headers: headers,
                method: request.method,
                url: '/api/' + request.endpoint,
                params: request.params,
                data: request.data
            };

            appInsights.trackTrace({ severityLevel: severityLevel.Information, message: "Starting request" }, { request: request });
            const response = await axios.request(axiosRequestConfig);

            // Success handling
            if (callbacks.successMessageCallback) {
                // Only dispatch when there is a message
                let message = callbacks.successMessageCallback(response.data);
                const success = createSuccess(message);
                dispatch({ type: actionTypes.PUSH_SUCCESS, payload: success });
                setTimeout(() =>
                    dispatch({ type: actionTypes.POP_SUCCESS, payload: { id: success.id } }),
                    successMessageTimeoutMs);
            }
            return response;
        } catch (e) {
            // Error handling
            console.error(e);
            appInsights.trackException({ exception: e, severityLevel: severityLevel.Error }, { request: request });
            appInsights.trackTrace({ severityLevel: severityLevel.Error, message: "Request failed" }, { request: request, exception: e });

            if (e.code === 'ECONNABORTED') {
                error = createError(errorTypes.API_TIMEOUT_ERROR, e.message, "Időtúllépési hiba");
            } else {
                switch (e.response.status) {
                    case 400:
                        if (callbacks.errorMessageCallback)
                            error = createError(errorTypes.API_CLIENT_ERROR, e.response.data, callbacks.errorMessageCallback(e.response.data));
                        else
                            error = createError(errorTypes.API_CLIENT_ERROR, e.response.data, "Váratlan hiba");
                        break;
                    case 401:
                        error = createError(errorTypes.API_AUTHENTICATION_ERROR, e.response.data, "Autentikációs hiba");
                        break;
                    case 403:
                        error = createError(errorTypes.API_AUTHORIZATION_ERROR, e.response.data, "Hozzáférés megtagadva");
                        break;
                    case 500:
                        error = createError(errorTypes.API_SERVER_ERROR, e.response.data, "Váratlan hiba");
                        break;
                    default:
                        error = createError(errorTypes.API_OTHER_ERROR, e.response.data, "Váratlan hiba");
                        break;
                }
            }
            dispatch({ type: actionTypes.PUSH_ERROR, payload: error });
            throw error;
        }
    }
}