import * as actions from '../../auth/Auth.action.js';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.AuthReducer.user
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            requestGetUser: () => dispatch(actions.requestGetUser()),
        };
    }
};
