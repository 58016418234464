/* eslint-disable import/no-anonymous-default-export */
import * as actionTypes from "./Settings.action.types";

export const initialState = {
    users: [],
    companies: [],
    costCenters: [],
    taskTypes: [],
    taskGroups: [],
    taskType: null,
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_GET_USERS: {
            return {
                ...state,
                // isLoading: true,
                users: action.payload.data
            };
        }
        // case actionTypes.REQUEST_GET_USERS_FAIL:
        // case actionTypes.REQUEST_GET_USERS_SUCCESS: {
        //     return {
        //         ...state,
        //         isLoading: false,
        //     };
        // }
        case actionTypes.REQUEST_GET_COMPANIES: {
            return {
                ...state,
                companies: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_COST_CENTERS: {
            return {
                ...state,
                costCenters: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_TASK_TYPES: {
            return {
                ...state,
                taskTypes: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_TASK_GROUPS: {
            return {
                ...state,
                taskGroups: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_TASK_TYPE: {
            return {
                ...state,
                taskType: action.payload.data
            };
        }
        case actionTypes.CLEAR_TASK_TYPE: {
            return {
                ...state,
                taskType: null
            };
        }
        default:
            return state;
    }
}
