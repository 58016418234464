import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from "react";
import styles from "./TaskWindow.module.css";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Checkbox, Input, TextArea } from "@progress/kendo-react-inputs";
import { convertToDottedDateString, fromDottedDateString } from '../../Utils';
import { Label } from "@progress/kendo-react-labels";

const CustomFieldsForm = forwardRef((props, ref) => {
    const {
        customFields,
        customFieldsData,
        customFieldValidities,
        checkValidity
    } = props;

    const [currentCustomFieldsData, setCurrentCustomFieldsData] = useState(customFieldsData);
    const [currentCustomFieldValidities, setCurrentCustomFieldValidities] = useState(customFieldValidities);

    useEffect(() => {
        setCurrentCustomFieldsData(customFieldsData);
    }, [customFieldsData]);

    useEffect(() => {
        setCurrentCustomFieldValidities(customFieldValidities);
    }, [customFieldValidities]);

    // In order to get the state from the parent
    useImperativeHandle(ref, () => ({
        getCurrentCustomFieldsData: () => currentCustomFieldsData
    }));

    const renderCustomField = (customField) => {
        const onChange = (value) => {
            let newCustomFieldsData = { ...currentCustomFieldsData };
            let newCustomFieldValidities = { ...currentCustomFieldValidities };
            newCustomFieldsData[customField.id] = value;
            newCustomFieldValidities[customField.id] = true;
            setCurrentCustomFieldsData(newCustomFieldsData);
            setCurrentCustomFieldValidities(newCustomFieldValidities);
        };

        switch (customField.type) {
            case 'ShortText':
                return (<Input valid={checkValidity ? currentCustomFieldValidities[customField.id] : true} onChange={({ value }) => onChange(value)} value={currentCustomFieldsData[customField.id] ?? ''} />);
            case 'LongText':
                return (<TextArea autoSize={true} valid={checkValidity ? currentCustomFieldValidities[customField.id] : true} onChange={({ value }) => onChange(value)} value={currentCustomFieldsData[customField.id] ?? ''} />);
            case 'Link':
                return (<Input valid={checkValidity ? currentCustomFieldValidities[customField.id] : true} onChange={({ value }) => onChange(value)} value={currentCustomFieldsData[customField.id] ?? ''} />);
            case 'Date':
                return (<DatePicker valid={checkValidity ? currentCustomFieldValidities[customField.id] : true} onChange={({ value }) => onChange(convertToDottedDateString(value))} value={fromDottedDateString(currentCustomFieldsData[customField.id])} />);
            case 'CheckBox':
                return (<div style={{ width: '100%' }}><Checkbox onChange={({ value }) => onChange(value ? "Jelölve" : "Nincs jelölve")} value={currentCustomFieldsData[customField.id] === 'Jelölve'} /></div>);
        }
    };


    return (
        <Fragment>
            {customFields.map((field, idx) => {
                return (
                    <div key={field.id} className={styles.customFieldRow}>
                        <Label className={styles.customFieldLabel}>{`${field.name}${field.mandatory ? "*" : ""}`}</Label>
                        {renderCustomField(field, idx)}
                    </div>);
            })}
        </Fragment>
    );
});

export default CustomFieldsForm;