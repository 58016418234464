import { Typography } from "@progress/kendo-react-common";
import { expensePermissions, privileges } from "../../Enums";
import connector from './User.connector.js';
import { connect } from "react-redux";
import sceneStyles from "../Scenes.module.css";
import styles from "./User.module.css";

const User = (props) => {
    const {
        user
    } = props;

    return (
        <div className={sceneStyles.container}>
            <Typography.h3 > {user.name}</Typography.h3 >
            <div className={styles.userDataContainer}>
                <Typography.p>Email:</Typography.p>
                <Typography.p>{user.emailAddress}</Typography.p>
                <Typography.p>Jogosultság:</Typography.p>
                <Typography.p>{privileges[user.privilege]}</Typography.p>
                <Typography.p>Kiadások jogosultság:</Typography.p>
                <Typography.p>{expensePermissions[user.expensePermission]}</Typography.p>
            </div>
        </div >
    );
};
export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(User);