import { setData } from '../../store/reduxUtils';
import * as actionTypes from './Incomes.action.types';
import apiCall from '../../store/apiCall';


export function requestGetIncomes(year) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `income/list/${year}` },
            { errorMessageCallback: () => `${year} évi bevételek lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_INCOMES, data)))
            .catch(() => { });
}

export function requestGetYears() {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `income/listYears` },
            { errorMessageCallback: () => `Évek lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_YEARS, data)))
            .catch(() => { });
}

export function requestGetStatistics(year) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `income/getStatistics/${year}` },
            { errorMessageCallback: () => `${year} évi statisztikák lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_STATISTICS, data)))
            .catch(() => { });
}

export function requestPostUploadFile(file, onSuccess) {
    return (dispatch, getState) => {
        let formData = new FormData();
        formData.append("file", file);
        return apiCall(
            dispatch, getState, { method: 'post', endpoint: `income/uploadFile`, data: formData },
            {
                successMessageCallback: () => "Fájl feltöltése sikeres",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidValue": return `Hibás érték (${error.payload.value}) a ${error.payload.row}. sorban, ${error.payload.column} oszlopban`;
                        case "InvalidExcelData": return `Hibás formátum a ${error.payload.row}. sorban, ${error.payload.column} oszlopban`;
                        case "InvalidExcel": return `Hibás Excel struktúra`;
                        case "InvalidFile": return `A feltöltött fájl nem Excel fájl`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_POST_UPLOAD_FILE, { fileName: file.name }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
    };
}

export function requestAddIncome(income, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `income/add`, data: income },
            {
                successMessageCallback: () => "Bevétel hozzáadása sikeres",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidValue": return `Hibás érték: ${error.payload.value}`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_ADD_INCOME, income));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestDeactivate(ids, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `income/deactivate`, data: ids },
            {
                successMessageCallback: () => "Sorok sikeresen deaktiválva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidIds": return `Nem megfelelő bevételek`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_DEACTIVATE, { ids: ids }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}