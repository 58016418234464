import * as authActions from '../../auth/Auth.action.js';
import * as responseActions from '../../store/Response.action.js';

export default {
    mapStateToProps: (state) => {
        return {
            user: state.AuthReducer.user,
            successes: state.ResponseReducer.successes,
            errors: state.ResponseReducer.errors,
            loading: state.ResponseReducer.loading
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            requestGetUser: () => dispatch(authActions.requestGetUser()),
            popError: (id) => dispatch(responseActions.popError(id)),
            popSuccess: (id) => dispatch(responseActions.popSuccess(id))
        };
    }
};
