import * as actionTypes from './Response.action.types';

export function pushError(error) {
    return (dispatch) => dispatch({ type: actionTypes.PUSH_ERROR, payload: error });
}

export function pushSuccess(success) {
    return (dispatch) => dispatch({ type: actionTypes.PUSH_SUCCESS, payload: success });
}

export function popError(id) {
    return (dispatch) => dispatch({ type: actionTypes.POP_ERROR, payload: { id } });
}

export function popSuccess(id) {
    return (dispatch) => dispatch({ type: actionTypes.POP_SUCCESS, payload: { id } });
}

export function setLoading() {
    return (dispatch) => dispatch({ type: actionTypes.SET_LOADING });
}

export function clearLoading() {
    return (dispatch) => dispatch({ type: actionTypes.CLEAR_LOADING });
}