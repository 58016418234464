import { setData } from '../../store/reduxUtils';
import * as actionTypes from './Tasks.action.types';
import apiCall from '../../store/apiCall';

export function requestGetNewTaskTypes() {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskType/ListNewTaskCardTypes` },
            { errorMessageCallback: () => `Feladattípusok lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_NEW_TASK_TYPES, data)))
            .catch(() => { });
}

export function requestGetTasks(params, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskList/ListCards`, params: params },
            {
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "BothFromAndToMustBeProvided": return `Kezdeti és véghatáridőt meg kell adni`;
                        default: return `Feladatok lekérése sikertelen`;
                    }
                }
            })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_TASKS, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestGetAllUsers(onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `user/ListUserWithId` },
            { errorMessageCallback: () => `Felhasználók lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_ALL_USERS, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestGetUsersByMinPrivilege(minPrivilege, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `user/ListUserWithAtLeastPrivilege/${minPrivilege}` },
            { errorMessageCallback: () => `Felhasználók lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_USERS_BY_MIN_PRIVILEGE, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestSetTaskStatus(statusUpdate, onSuccess, onFailure) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `taskList/SetStatus`, data: statusUpdate },
            {
                successMessageCallback: () => "Állapot sikeresen módosítva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidFieldData": return `Hibás státusz`;
                        case "ConcurrentChange": return `Időközben más módosította a feladatot`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_SET_TASK_STATUS, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => {
                if (onFailure) onFailure();
            });
}

export function requestGetTaskType(id, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskType/GetType/${id}` },
            { errorMessageCallback: () => `Feladattípus lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_TASK_TYPE, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestCreateTask(task, onSuccess, onFailure) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `taskEdit/CreateTask`, data: task },
            {
                successMessageCallback: () => "Feladat sikeresen létrehozva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidTaskType": return `Feladattípus nem létezik`;
                        case "NoAccessAssignee": return `Felelősnek nincsen hozzáférése a feladathoz`;
                        case "InvalidFieldData": return `Hibás érték a ${error.payload.field} mezőben`;
                        case "MissingFieldData": return `Hiányzó érték a ${error.payload.field} mezőben`;
                        case "InvalidCustomData": return `Hiányzó vagy rossz formátumú értékek`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_CREATE_TASK, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => {
                if (onFailure) onFailure();
            });
}

export function requestUpdateTask(task, onSuccess, onFailure) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `taskEdit/UpdateTask`, data: task },
            {
                successMessageCallback: () => "Feladat sikeresen módosítva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "NoAccessAssignee": return `Felelősnek nincsen hozzáférése a feladathoz`;
                        case "InvalidFieldData": return `Hibás érték a ${error.payload.field} mezőben`;
                        case "MissingFieldData": return `Hiányzó érték a ${error.payload.field} mezőben`;
                        case "InvalidCustomData": return `Hiányzó vagy rossz formátumú értékek`;
                        case "ConcurrentChange": return `Időközben más módosította a feladatot`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_UPDATE_TASK, { task, rowVersion: data }));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => {
                if (onFailure) onFailure();
            });
}

export function requestGetTask(id, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskEdit/GetTask/${id}` },
            { errorMessageCallback: () => `Feladat lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_TASK, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestRemoveTask(id, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'delete', endpoint: `taskEdit/RemoveTask/${id}` },
            { errorMessageCallback: () => `Feladat törlése sikertelen` })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_REMOVE_TASK, id));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestGetTaskIsFollow(id) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskEdit/IsFollow/${id}` },
            { errorMessageCallback: () => `Feladat követési állapotának lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_TASK_ISFOLLOW, data));
            })
            .catch(() => { });
}

export function requestSetTaskFollow(id) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `taskEdit/Follow/${id}` },
            { errorMessageCallback: () => `Feladat bekövetése sikertelen` })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_SET_TASK_FOLLOW, id));
            })
            .catch(() => { });
}

export function requestSetTaskUnfollow(id) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `taskEdit/Unfollow/${id}` },
            { errorMessageCallback: () => `Feladat kikövetése sikertelen` })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_SET_TASK_UNFOLLOW, id));
            })
            .catch(() => { });
}

export function requestGetTaskHistory(taskId, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskEdit/GetHistory/${taskId}` },
            { errorMessageCallback: () => `Feladat állapot történetének lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_TASK_HISTORY, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestGetTaskAttachments(taskId, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskEdit/ListAttachments/${taskId}` },
            { errorMessageCallback: () => `Feladat csatolmányainak lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_TASK_ATTACHMENTS, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestGetTaskAttachmentLink(attachmentId, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskEdit/GetAttachmentLink/${attachmentId}` },
            { errorMessageCallback: () => `Csatolmány lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_TASK_ATTACHMENT_LINK, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestRemoveTaskAttachment(attachmentId, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'delete', endpoint: `taskEdit/RemoveAttachment/${attachmentId}` },
            { errorMessageCallback: () => `Csatolmány törlése sikertelen` })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_REMOVE_TASK_ATTACHMENT, attachmentId));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestAddTaskAttachment(taskId, file, onSuccess) {
    return (dispatch, getState) => {
        let formData = new FormData();
        formData.append("file", file);
        return apiCall(
            dispatch, getState, { method: 'post', endpoint: `taskEdit/AttachFile/${taskId}`, params: { taskId: taskId }, data: formData },
            { errorMessageCallback: () => `Fájl csatolása sikertelen` })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_ADD_TASK_ATTACHMENT, { fileName: file.name }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
    };
}

export function clearTask() {
    return (dispatch) => dispatch({ type: actionTypes.CLEAR_TASK });
}

export function clearAttachmentLink() {
    return (dispatch) => dispatch({ type: actionTypes.CLEAR_TASK_ATTACHMENT_LINK });
}