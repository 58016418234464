/* eslint-disable import/no-anonymous-default-export */
import * as expenseActions from './Expenses.action.js';
import { convertToLocalTime, convertToDottedDateTimeString } from '../../Utils.js';
import { requestGetCompanies } from '../settings/Settings.action.js';
import { requestGetCostCenters } from '../settings/Settings.action.js';

export default {
    mapStateToProps: (state) => {
        return {
            expenses: state.ExpensesReducer.expenses.map(e => (
                {
                    ...e,
                    date: new Date(e.date),
                    month: 'M' + e.month,
                    uploadDate: convertToLocalTime(new Date(e.uploadDate)),
                    comment: e.isActive ? e.comment : (e.comment ?? "") + ` Deaktiválva ${convertToDottedDateTimeString(convertToLocalTime(new Date(e.deactivateTime)))}-kor ${e.deactivateUser} által.`
                })),
            years: state.ExpensesReducer.years,
            statistics: state.ExpensesReducer.statistics,
            parkings: state.SettingsReducer.companies.map(c => c.parkings.map(p => p.name)).flat().sort(),
            expenseTypes: state.SettingsReducer.costCenters.map(c => c.expenseTypes.map(e => e.name)).flat(),
            user: state.AuthReducer.user
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            requestGetExpenses: (year) => dispatch(expenseActions.requestGetExpenses(year)),
            requestGetYears: () => dispatch(expenseActions.requestGetYears()),
            requestGetStatistics: (year) => dispatch(expenseActions.requestGetStatistics(year)),
            requestGetParkings: () => dispatch(requestGetCompanies()),
            requestGetExpenseTypes: () => dispatch(requestGetCostCenters()),
            requestPostUploadFile: (file, onSuccess) => dispatch(expenseActions.requestPostUploadFile(file, onSuccess)),
            requestAddExpense: (expense, onSuccess) => dispatch(expenseActions.requestAddExpense(expense, onSuccess)),
            requestDeactivate: (ids, onSuccess) => dispatch(expenseActions.requestDeactivate(ids, onSuccess))
        };
    }
};
