import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./Settings.module.css";
import sceneStyles from "../Scenes.module.css";
import { privileges } from "../../Enums";
import { Typography } from "@progress/kendo-react-common";
import { Switch } from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { TaskTypeEdit } from "./TaskTypeEdit";

export const TaskTypeSettings = (props) => {
    const {
        taskTypes,
        taskGroups,
        taskType,
        requestGetTaskTypes,
        requestGetTaskType,
        requestGetTaskGroups,
        requestAddTaskType,
        requestUpdateTaskType,
        requestSetTaskTypeActive,
        requestSetTaskTypeInactive,
        clearTaskType,
        setLeaveDialogData
    } = props;

    const [taskTypeData, setTaskTypeData] = useState([]);

    const [page, setPage] = useState({ skip: 0, take: 10 });
    const [pageSizeValue, setPageSizeValue] = useState(10);
    const [activeDialogData, setActiveDialogData] = useState({ visible: false, newValue: false, taskType: null });
    const [taskEditScreenData, setTaskEditScreenData] = useState({ visible: false, isNewTaskType: true });

    useEffect(() => {
        setTaskTypeData(taskTypes);
    }, [taskTypes]);

    const pageChange = (e) => {
        const take = e.targetEvent.value === "Mind" ? taskTypeData.length : e.page.take;
        if (e.targetEvent.value) {
            setPageSizeValue(e.targetEvent.value);
        }
        setPage({
            ...e.page,
            take,
        });
    };

    const renderName = (props) => {
        return (
            <td>
                <a href="/" onClick={e => {
                    e.preventDefault();
                    // Gets selected task type
                    requestGetTaskType(props.dataItem.typeId);
                    setTaskEditScreenData({ visible: true, isNewTaskType: false });
                }}>{props.dataItem[props.field]}</a>
            </td>
        );
    };

    const renderPrivilege = (props) => (
        <td>
            {privileges[props.dataItem[props.field]]}
        </td>
    );

    const renderColor = (props) => (
        <td>
            <div className={styles.colorBox} style={{ backgroundColor: props.dataItem[props.field] }}></div>
        </td>
    );

    const renderActive = (props) => (
        <td>
            <Switch
                onChange={(e) => setActiveDialogData({ visible: true, newValue: e.target.value, taskType: props.dataItem })}
                checked={props.dataItem[props.field]} />
        </td>
    );

    const closeActiveDialog = () => setActiveDialogData({ visible: false });

    const handleCloseEditScreen = () => {
        setTaskEditScreenData({ visible: false });
        clearTaskType();
    };

    return <div>
        {!taskEditScreenData.visible ? <div>
            <div className={styles.tableContainer}>
                <Button
                    className={styles.addButton}
                    themeColor={"primary"}
                    onClick={() => {
                        setTaskEditScreenData({ visible: true, isNewTaskType: true });
                    }}
                >
                    Új feladattípus
                </Button>
                <Grid
                    data={taskTypeData.slice(page.skip, page.take + page.skip)}
                    className={styles.dataTable}
                    sortable={true}
                    skip={page.skip}
                    take={page.take}
                    total={taskTypeData.length}
                    pageable={{
                        buttonCount: 4,
                        pageSizes: [10, 25, 50, "Mind"],
                        pageSizeValue: pageSizeValue,
                    }}
                    onPageChange={pageChange}
                    scrollable="none"
                // resizable={true}
                >
                    <GridColumn
                        width={180}
                        field="name"
                        title="Név"
                        cell={(props) => renderName(props)}
                    />
                    <GridColumn width={180} field="group" title="Csoport" />
                    <GridColumn
                        width={180}
                        title="Jogosultsági szint"
                        field="privilege"
                        cell={(props) => renderPrivilege(props)}
                    />
                    <GridColumn
                        width={120}
                        title="Szín"
                        field="color"
                        cell={(props) => renderColor(props)}
                    />
                    <GridColumn
                        width={120}
                        title="Aktív"
                        field="active"
                        cell={(props) => renderActive(props)}
                    />
                </Grid>
            </div>
            {activeDialogData.visible && (
                <Dialog
                    width={400}
                    title={"Megerősítés"}
                    onClose={closeActiveDialog}
                    autoFocus={true}
                >
                    <Typography.p>{(activeDialogData.newValue ? "Aktiválja " : "Deaktiválja ") + `a(z) ${activeDialogData.taskType.name} feladattípust?`} </Typography.p>
                    <DialogActionsBar>
                        <div className={sceneStyles.dialogActionsBar}>
                            <Button
                                onClick={closeActiveDialog}
                            >
                                Nem
                            </Button>
                            <Button
                                themeColor="primary"
                                onClick={() => {
                                    if (activeDialogData.newValue) {
                                        requestSetTaskTypeActive(activeDialogData.taskType.typeId, () => requestGetTaskTypes());
                                    } else {
                                        requestSetTaskTypeInactive(activeDialogData.taskType.typeId, () => requestGetTaskTypes());
                                    }
                                    closeActiveDialog();
                                }}
                            >
                                Igen
                            </Button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}
        </div> : <TaskTypeEdit
            taskGroups={taskGroups}
            taskType={taskType}
            requestGetTaskTypes={requestGetTaskTypes}
            requestGetTaskGroups={requestGetTaskGroups}
            requestAddTaskType={requestAddTaskType}
            requestUpdateTaskType={requestUpdateTaskType}
            handleCloseEditScreen={handleCloseEditScreen}
            isNewTaskType={taskEditScreenData.isNewTaskType}
            setLeaveDialogData={setLeaveDialogData}
        />}
    </div>;
};