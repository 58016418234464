import React, { useState, useEffect, createRef, cloneElement } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import styles from "./Expenses.module.css";
import sceneStyles from "../Scenes.module.css";
import { connect } from 'react-redux';
import connector from './Expenses.connector.js';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useRef } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Checkbox, Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { PDFExport } from "@progress/kendo-react-pdf";
import { Typography } from "@progress/kendo-react-common";
import { process } from "@progress/kendo-data-query";
import { ColumnMenuCheckboxFilter, ColumnMenuMonthFilter, ColumnMenuDayFilter } from "../../components/gridUtils/ColumnMenu";

const allParkingName = "Mindegyik";
const Expenses = (props) => {
    const {
        requestGetYears,
        requestGetExpenses,
        requestGetStatistics,
        requestGetParkings,
        requestGetExpenseTypes,
        requestPostUploadFile,
        requestAddExpense,
        requestDeactivate,
        years,
        expenses,
        statistics,
        parkings,
        expenseTypes,
        user
    } = props;

    const [selectedYear, setSelectedYear] = useState();
    const [dataState, setDataState] = useState({ skip: 0, take: 10 });
    const [showedExpenses, setShowedExpenses] = useState({ data: [], total: 0 });

    const [selectedIds, setSelectedIds] = useState([]);

    const [newRowDialogVisible, setNewRowDialogVisible] = useState(false);
    const [deactivateDialogVisible, setDeactivateDialogVisible] = useState(false);

    const [commonExpenseField, setCommonExpenseField] = useState(false);

    const fileInput = useRef(null);
    const excelExport = useRef(null);

    const pdfExportRefs = useRef([]);

    if (statistics?.tabs?.length && pdfExportRefs.current.length !== statistics.tabs.length) {
        pdfExportRefs.current = Array(statistics.tabs.length).fill().map((_, i) => pdfExportRefs.current[i] || createRef());
    }

    useEffect(() => {
        requestGetYears();
    }, [requestGetYears]);

    useEffect(() => {
        if (!selectedYear && years[0])
            setSelectedYear(years[0]);
    }, [years]);

    useEffect(() => {
        setShowedExpenses(process(expenses, dataState));
    }, [expenses, dataState]);

    useEffect(() => {
        if (selectedYear) {
            requestGetExpenses(selectedYear);
        }
    }, [requestGetExpenses, selectedYear]);

    useEffect(() => {
        if (selectedYear)
            requestGetStatistics(selectedYear);
    }, [requestGetStatistics, selectedYear]);

    useEffect(() => {
        requestGetParkings();
    }, [requestGetParkings]);

    useEffect(() => {
        requestGetExpenseTypes();
    }, [requestGetExpenseTypes]);

    const [selectedTab, setSelectedTab] = useState(0);

    const handleFileChange = (e) => {
        if (e.target.files[0]) {
            requestPostUploadFile(e.target.files[0], handleRefresh);
            e.target.value = null;
        }
    };
    const handleDownload = (e) => {
        if (selectedTab === 0) {
            if (excelExport.current) {
                excelExport.current.save();
            }
        } else {
            if (pdfExportRefs.current[selectedTab - 1].current) {
                pdfExportRefs.current[selectedTab - 1].current.save();
            }
        }
    };
    const handleRefresh = () => {
        setSelectedIds([]);
        requestGetYears();
        if (selectedYear) {
            requestGetExpenses(selectedYear);
            requestGetStatistics(selectedYear);
        }
    };

    const rowRender = (row, props) => {
        if (!props.dataItem.isActive)
            return cloneElement(row, { className: row.props.className + " k-disabled" }, row.props.children);
        else
            return row;
    };

    const renderSelectedCell = (props) => {
        const onChange = (e) => {
            if (e.value) {
                setSelectedIds([...selectedIds, props.dataItem.id]);
            } else {
                setSelectedIds(selectedIds.filter(id => id !== props.dataItem.id));
            }
        };
        return (<td>
            <Checkbox checked={selectedIds.includes(props.dataItem.id)} onChange={onChange} />
        </td>);
    };

    const isAllChecked = () => {
        const activeLength = showedExpenses.data.filter(item => item.isActive).length;
        return activeLength !== 0 && Math.min(activeLength, dataState.take) === selectedIds.length;
    };

    const onRowHeaderSelectionChange = (e) => {
        if (isAllChecked())
            setSelectedIds([]);
        else
            setSelectedIds(e.dataItems.filter(item => item.isActive).map(item => item.id));
    };

    const handleDialogOpen = () => {
        setCommonExpenseField(false);
        setNewRowDialogVisible(true);
    };

    const handleRowDialogClose = () => {
        setNewRowDialogVisible(false);
    };

    const handleDeactivateDialogClose = () => {
        setDeactivateDialogVisible(false);
    };

    return (
        <div className={sceneStyles.container}>
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
            />
            <div className={sceneStyles.titleMenu}>
                <DropDownList
                    data={years}
                    value={selectedYear}
                    onChange={(e) => {
                        setSelectedTab(0);
                        setSelectedYear(e.target.value);
                    }}
                    fillMode="outline"
                />
                <Button fillMode="flat" disabled={user.expensePermission !== "Write"} iconClass="fa-solid fa-lg fa-plus" onClick={handleDialogOpen} themeColor="primary" />
                <Button fillMode="flat" disabled={user.expensePermission !== "Write"} iconClass="fa-solid fa-lg fa-file-arrow-up" onClick={(e) => fileInput.current.click()} themeColor="primary" />
                <input type="file" ref={fileInput} onChange={handleFileChange} hidden />
                <Button fillMode="flat" iconClass="fa-solid fa-lg fa-file-arrow-down" onClick={handleDownload} themeColor="primary" disabled={!selectedYear} />
                <Button fillMode="flat" iconClass="fa-solid fa-trash" onClick={() => setDeactivateDialogVisible(true)} themeColor="primary" disabled={selectedIds.length === 0 || user.expensePermission !== "Write"} />
                <Button fillMode="flat" iconClass="fa-solid fa-lg fa-arrows-rotate" onClick={handleRefresh} themeColor="primary" />
            </div>
            <TabStrip selected={selectedTab} onSelect={(e) => setSelectedTab(e.selected)}>
                <TabStripTab contentClassName={sceneStyles.tabStripContent} title="Kiadások">
                    <ExcelExport data={expenses} fileName={`Kiadások ${selectedYear}.xlsx`} ref={excelExport}>
                        <ExcelExportColumn field="date" title="Dátum" cellOptions={{ format: "yyyy.MM.dd." }} />
                        <ExcelExportColumn field="expenseType" title="Költségtípus" />
                        <ExcelExportColumn field="parking" title="Parkoló" />
                        <ExcelExportColumn field="costCenter" title="Költséghely" />
                        <ExcelExportColumn field="month" title="Hónap" />
                        <ExcelExportColumn field="company" title="Cég" />
                        <ExcelExportColumn field="amount" title="Összeg" cellOptions={{ format: "#,##0 [$Ft];[RED]-#,##0 [$Ft]" }} />
                        <ExcelExportColumn field="provider" title="Szolgáltató" />
                        <ExcelExportColumn field="comment" title="Komment / Leírás" />
                        <ExcelExportColumn field="uploader" title="Feltöltő" />
                        <ExcelExportColumn field="uploadDate" title="Feltöltés dátuma" cellOptions={{ format: "yyyy.MM.DD. HH:mm:SS" }} />
                    </ExcelExport>
                    <Grid
                        className={sceneStyles.table}
                        data={showedExpenses}
                        selectedField="selected"
                        selectable={true}
                        onHeaderSelectionChange={onRowHeaderSelectionChange}
                        onDataStateChange={e => {
                            setDataState(e.dataState);
                            setSelectedIds([]);
                        }}
                        {...dataState}
                        total={showedExpenses.length}
                        pageable={{
                            buttonCount: 4,
                            pageSizes: [10, 25, 50, 100],
                            pageSizeValue: dataState.take,
                        }}
                        sortable={true}
                        scrollable="scrollable"
                        resizable={true}
                        rowRender={rowRender}
                    >
                        <GridColumn
                            cell={renderSelectedCell} field="selected" width="50px" headerSelectionValue={isAllChecked()}
                        />
                        <GridColumn width={140} field="date" format="{0:yyyy.MM.dd.}" title="Fizetési dátum" columnMenu={(props) => ColumnMenuMonthFilter({ ...props, year: selectedYear })} />
                        <GridColumn width={150} field="expenseType" title="Költségtípus" columnMenu={(props) => ColumnMenuCheckboxFilter({ ...props, data: expenses })} />
                        <GridColumn width={150} field="parking" title="Parkoló" columnMenu={(props) => ColumnMenuCheckboxFilter({ ...props, data: expenses })} />
                        <GridColumn width={150} field="costCenter" title="Költséghely" columnMenu={(props) => ColumnMenuCheckboxFilter({ ...props, data: expenses })} />
                        <GridColumn width={150} field="company" title="Cég" columnMenu={(props) => ColumnMenuCheckboxFilter({ ...props, data: expenses })} />
                        <GridColumn width={150} field="amount" format="{0:0, Ft}" title="Összeg" />
                        <GridColumn width={180} field="provider" title="Szolgáltató" />
                        <GridColumn width={180} field="comment" title="Komment / Leírás" />
                        <GridColumn width={180} field="uploader" title="Feltöltő" columnMenu={(props) => ColumnMenuCheckboxFilter({ ...props, data: expenses })} />
                        <GridColumn width={210} field="uploadDate" format="{0:yyyy.MM.dd. HH:mm:ss}" title="Feltöltés dátuma" columnMenu={(props) => ColumnMenuDayFilter({ ...props, data: expenses })} />
                    </Grid>
                </TabStripTab>
                {statistics.tabs ? statistics.tabs.map((tab, idx) => {
                    return <TabStripTab key={tab.company} title={tab.company} contentClassName={sceneStyles.tabStripContent}>
                        <div className={styles.pdfExportFont}>
                            <PDFExport
                                ref={pdfExportRefs.current[idx]}
                                margin={40}
                                fileName={`${tab.company} kiadási statisztikák ${selectedYear}`}
                            >
                                {tab.tables.map(table => <div key={table.title}>
                                    <Typography.h5>{table.title}</Typography.h5>
                                    <Grid
                                        data={table.rows}
                                        sortable={true}
                                        scrollable="scrollable"
                                        resizable={true}
                                        className={sceneStyles.statisticsTable}
                                    >
                                        <GridColumn width={150} field="name" title={table.title} />
                                        <GridColumn width={150} field="total" format="{0:0, Ft}" title="Total Huf" />
                                        <GridColumn width={150} field="average" format="{0:0, Ft}" title="Avr. Huf" />
                                        <GridColumn width={150} field="m1" format="{0:0, Ft}" title="M1" />
                                        <GridColumn width={150} field="m2" format="{0:0, Ft}" title="M2" />
                                        <GridColumn width={150} field="m3" format="{0:0, Ft}" title="M3" />
                                        <GridColumn width={150} field="m4" format="{0:0, Ft}" title="M4" />
                                        <GridColumn width={150} field="m5" format="{0:0, Ft}" title="M5" />
                                        <GridColumn width={150} field="m6" format="{0:0, Ft}" title="M6" />
                                        <GridColumn width={150} field="m7" format="{0:0, Ft}" title="M7" />
                                        <GridColumn width={150} field="m8" format="{0:0, Ft}" title="M8" />
                                        <GridColumn width={150} field="m9" format="{0:0, Ft}" title="M9" />
                                        <GridColumn width={150} field="m10" format="{0:0, Ft}" title="M10" />
                                        <GridColumn width={150} field="m11" format="{0:0, Ft}" title="M11" />
                                        <GridColumn width={150} field="m12" format="{0:0, Ft}" title="M12" />
                                    </Grid>
                                </div>
                                )}
                            </PDFExport>
                        </div>
                    </TabStripTab>;
                }
                ) : null}

            </TabStrip>
            {newRowDialogVisible && (
                <Dialog
                    width={500}
                    title={"Új kiadás hozzáadása"}
                    onClose={handleRowDialogClose}
                    autoFocus={true}
                >
                    <Form
                        onSubmit={(values, e) => {
                            const { commonExpense, ...request } = values;
                            if (commonExpense)
                                request.parking = allParkingName;
                            requestAddExpense(request, handleRefresh);
                            setNewRowDialogVisible(false);
                        }}
                        initialValues={{
                            date: new Date(),
                            expenseType: expenseTypes[0],
                            parking: parkings[0],
                            commonExpense: commonExpenseField,
                            amount: null,
                            provider: "",
                            comment: ""
                        }}
                        render={(formProps) => (
                            <FormElement className={sceneStyles.dialogContainer}>
                                <div className={sceneStyles.dialogForm}>
                                    <Field
                                        name="date"
                                        label="Kiadás dátuma"
                                        required={true}
                                        component={DatePicker}
                                    />
                                    <Field
                                        name="expenseType"
                                        label="Költségtípus"
                                        data={expenseTypes}
                                        validator={(value) => expenseTypes.includes(value) ? "" : "Nem megfelelő költségtípus"}
                                        required={true}
                                        component={DropDownList}
                                    />
                                    <Field
                                        name="parking"
                                        label="Parkoló"
                                        data={parkings}
                                        validator={(value) => parkings.includes(value) ? "" : "Nem megfelelő parkoló"}
                                        required={true}
                                        disabled={commonExpenseField}
                                        component={DropDownList}
                                    />
                                    <Field
                                        name="commonExpense"
                                        label="Mindegyik parkolóhoz tartozik"
                                        labelPlacement={"after"}
                                        component={Checkbox}
                                        onChange={({ value }) => setCommonExpenseField(value)}
                                    />
                                    <Field
                                        name="amount"
                                        label="Összeg (Ft)"
                                        validator={(value) => value ? "" : "Üres érték"}
                                        spinners={false}
                                        required={true}
                                        component={NumericTextBox}
                                    />
                                    <Field
                                        name="provider"
                                        label="Szolgáltató"
                                        required={false}
                                        component={Input}
                                    />
                                    <Field
                                        name="comment"
                                        label="Komment/leírás"
                                        required={false}
                                        component={Input}
                                    />
                                </div>
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Hozzáadás
                                    </Button>
                                </div>
                            </FormElement>
                        )}
                    />
                </Dialog>
            )}
            {deactivateDialogVisible && (
                <Dialog
                    width={400}
                    title={"Megerősítés"}
                    onClose={handleDeactivateDialogClose}
                    autoFocus={true}
                >
                    <Typography.p>{`Biztosan deaktiválja a kiválasztott sorokat?`} </Typography.p>
                    <DialogActionsBar>
                        <div className={sceneStyles.dialogActionsBar}>
                            <Button
                                onClick={handleDeactivateDialogClose}
                            >
                                Nem
                            </Button>
                            <Button
                                themeColor="primary"
                                onClick={() => {
                                    requestDeactivate(selectedIds, handleRefresh);
                                    handleDeactivateDialogClose();
                                }}
                            >
                                Igen
                            </Button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}
        </div >
    );
};

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Expenses);
