import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./Settings.module.css";
import { expensePermissions, privileges } from "../../Enums";

export const UserSettings = (props) => {
    const {
        users,
        requestGetUser,
        requestGetUsers,
        requestSetUserPrivileges
    } = props;

    const [usersData, setUsersData] = useState([]);

    const [page, setPage] = useState({ skip: 0, take: 10 });
    const [pageSizeValue, setPageSizeValue] = useState(10);
    const [dataChanged, setDataChanged] = useState(false);

    useEffect(() => {
        setUsersData(users);
    }, [users]);

    const pageChange = (e) => {
        const take = e.targetEvent.value === "Mind" ? usersData.length : e.page.take;
        if (e.targetEvent.value) {
            setPageSizeValue(e.targetEvent.value);
        }
        setPage({
            ...e.page,
            take,
        });
    };

    const handleSaveUsers = () => {
        const privileges = usersData.map(user => ({ userId: user.id, privilege: user.privilege, expensePermission: user.expensePermission }));
        requestSetUserPrivileges(privileges, () => {
            requestGetUsers();
            requestGetUser();
            setDataChanged(false);
        });
    };

    const onListDataChange = (props) => (e) => {
        setDataChanged(true);
        props.dataItem[props.field] = e.value.value;
        setUsersData([
            ...usersData.slice(0, props.dataIndex),
            props.dataItem,
            ...usersData.slice(props.dataIndex + 1)
        ]);
    };

    const privilegeListData = Object.keys(privileges).map(p => ({ value: p, text: privileges[p] }));
    const renderPrivilegeList = (props) => (
        <td>
            <DropDownList
                data={privilegeListData}
                textField="text"
                value={privilegeListData.find(data => data.value === props.dataItem[props.field]) ||
                    privilegeListData[0]}
                onChange={onListDataChange(props)} />
        </td>
    );

    const expensePermissionsListData = Object.keys(expensePermissions).map(p => ({ value: p, text: expensePermissions[p] }));
    const renderExpensePermissionList = (props) => (
        <td>
            <DropDownList
                data={expensePermissionsListData}
                textField="text"
                value={expensePermissionsListData.find(data => data.value === props.dataItem[props.field]) ||
                    expensePermissionsListData[0]}
                onChange={onListDataChange(props)} />
        </td>
    );

    return <div>
        <Grid
            data={usersData.slice(page.skip, page.take + page.skip)}
            sortable={true}
            skip={page.skip}
            take={page.take}
            total={usersData.length}
            pageable={{
                buttonCount: 4,
                pageSizes: [10, 25, 50, "Mind"],
                pageSizeValue: pageSizeValue,
            }}
            onPageChange={pageChange}
            scrollable="none"
        >
            <GridColumn width={180} field="name" title="Név" />
            <GridColumn width={250} field="emailAddress" title="Email" />
            <GridColumn
                width={210}
                title="Jogosultság"
                field="privilege"
                cell={(props) => renderPrivilegeList(props)}
            />
            <GridColumn
                width={210}
                title="Kiadások jogosultság"
                field="expensePermission"
                cell={(props) => renderExpensePermissionList(props)}
            />
        </Grid>
        <Button
            disabled={!dataChanged}
            themeColor={"primary"}
            className={styles.saveButton}
            onClick={handleSaveUsers}
        >
            Mentés
        </Button>
    </div>;
};