import { GridColumnMenuCheckboxFilter, } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import styles from "./GridUtils.module.css";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";

export const ColumnMenuCheckboxFilter = (props) => {
    return (
        <GridColumnMenuCheckboxFilter
            {...props}
            data={props.data}
            expanded={true}
            searchBoxFilterOperator="contains"
        />
    );
};

const monthNames = ["Január", "Február", "Március", "Április", "Május", "Június",
    "Július", "Augusztus", "Szeptember", "Október", "November", "December"];

export const ColumnMenuMonthFilter = (props) => {

    const [checkedStates, setCheckedStates] = useState(Array(12).fill(false));
    const [columnFilters, setColumnFilters] = useState({ logic: 'or', filters: [], field: props.column.field });

    useEffect(() => {
        if (!props.filter)
            return;
        let monthFilters = props.filter.filters.find(filter => filter.field === props.column.field);
        if (monthFilters) {
            setColumnFilters({ ...columnFilters, filters: monthFilters.filters });
            let newCheckedStates = Array(12).fill(false);
            monthFilters.filters.forEach(filter => {
                newCheckedStates[filter.month] = true;
            });
            setCheckedStates(newCheckedStates);
        }
    }, []);

    const onCheck = (e, month) => {
        if (e.value) {
            setColumnFilters({
                ...columnFilters, filters: [...columnFilters.filters,
                {
                    logic: 'and', month: month, filters: [
                        { operator: 'gte', value: new Date(props.year, month, 1), field: props.column.field },
                        { operator: 'lt', value: new Date(props.year, month + 1, 1), field: props.column.field }
                    ]
                }]
            });
            let newCheckedStates = checkedStates.slice();
            newCheckedStates[month] = true;
            setCheckedStates(newCheckedStates);
        }
        else {
            // On unchecking
            setColumnFilters({ ...columnFilters, filters: columnFilters.filters.filter(filter => filter.month !== month) });
            let newCheckedStates = checkedStates.slice();
            newCheckedStates[month] = false;
            setCheckedStates(newCheckedStates);
        }
    };

    const onSave = (e) => {
        if (columnFilters.filters.length === 0) {
            onClear();
            return;
        }

        if (props.filter) {
            props.filter.filters = [...props.filter.filters.filter(filter => filter.field !== props.column.field), columnFilters];
            props.onFilterChange(props.filter, e);
        } else if (columnFilters.filters.length !== 0) {
            // If this is the first filter
            props.filter = { logic: "and", filters: [columnFilters] };
            props.onFilterChange(props.filter, e);
        }
        props.onCloseMenu();
    };

    const onClear = (e) => {
        if (props.filter) {
            props.filter.filters = props.filter.filters.filter(filter => filter.field !== props.column.field);
            props.onFilterChange(props.filter, e);
        }
        setCheckedStates(Array(12).fill(false));
        props.onCloseMenu();
    };

    return (
        <div className={styles.menuContainer}>
            <div className={styles.checkboxList}>
                {monthNames.map((month, i) => <Checkbox key={month} label={month} checked={checkedStates[i]} className={styles.checkboxItem} onChange={e => onCheck(e, i)} />)}
            </div>
            <span className={styles.selectedItems}>{`${columnFilters.filters.length} kiválasztott elem`}</span>
            <div className={styles.buttonsRow}>
                <Button className={styles.button} type="reset" onClick={onClear}>Szűrő törlése</Button>
                <Button className={styles.button} themeColor={"primary"} type="submit" onClick={onSave}>Szűrő</Button>
            </div>
        </div>
    );
};

export const ColumnMenuDayFilter = (props) => {

    const [columnFilters, setColumnFilters] = useState({ logic: 'and', filters: [], field: props.column.field });
    const [currentValue, setCurrentValue] = useState();

    useEffect(() => {
        if (!props.filter)
            return;
        let dayFilter = props.filter.filters.find(filter => filter.field === props.column.field);
        if (dayFilter) {
            setColumnFilters({ ...columnFilters, filters: dayFilter.filters });
            if (dayFilter.filters.length > 0) {
                setCurrentValue(new Date(dayFilter.filters[0].value));
            }
        }
    }, []);

    const onChange = (e) => {
        setCurrentValue(e.value);
        if (e.value) {
            setColumnFilters({
                ...columnFilters, filters: [
                    { operator: 'gte', value: e.value, field: props.column.field },
                    { operator: 'lt', value: new Date(e.value.getFullYear(), e.value.getMonth(), e.value.getDate() + 1), field: props.column.field }
                ]
            });
        }
        else {
            setColumnFilters({ ...columnFilters, filters: [] });
        }
    };

    const onSave = (e) => {
        if (columnFilters.filters.length === 0) {
            onClear();
            return;
        }

        if (props.filter) {
            props.filter.filters = [...props.filter.filters.filter(filter => filter.field !== props.column.field), columnFilters];
            props.onFilterChange(props.filter, e);
        } else if (columnFilters.filters.length !== 0) {
            // If this is the first filter
            props.filter = { logic: "and", filters: [columnFilters] };
            props.onFilterChange(props.filter, e);
        }
        props.onCloseMenu();
    };

    const onClear = (e) => {
        if (props.filter) {
            props.filter.filters = props.filter.filters.filter(filter => filter.field !== props.column.field);
            props.onFilterChange(props.filter, e);
        }
        setCurrentValue(null);
        props.onCloseMenu();
    };

    return (
        <div className={styles.menuContainer}>
            <div className={styles.checkboxList}>
                <DatePicker value={currentValue} onChange={onChange} />
            </div>
            <div className={styles.buttonsRow}>
                <Button className={styles.button} type="reset" onClick={onClear}>Szűrő törlése</Button>
                <Button className={styles.button} themeColor={"primary"} type="submit" onClick={onSave}>Szűrő</Button>
            </div>
        </div>
    );
};