export const REQUEST_GET_USERS = '@users/REQUEST_GET_USERS';
// export const REQUEST_GET_USERS_SUCCESS = '@users/REQUEST_GET_USERS_SUCCESS';
// export const REQUEST_GET_USERS_FAIL = '@users/REQUEST_GET_USERS_FAIL';
export const REQUEST_SET_USER_PRIVILEGES = '@users/REQUEST_SET_USER_PRIVILEGES';

export const REQUEST_GET_COMPANIES = '@masterData/REQUEST_GET_COMPANIES';
export const REQUEST_ADD_COMPANY = '@masterData/REQUEST_ADD_COMPANY';
export const REQUEST_ADD_PARKING = '@masterData/REQUEST_ADD_PARKING';
export const REQUEST_UPDATE_COMPANY = '@masterData/REQUEST_UPDATE_COMPANY';
export const REQUEST_UPDATE_PARKING = '@masterData/REQUEST_UPDATE_PARKING';
export const REQUEST_GET_COST_CENTERS = '@masterData/REQUEST_GET_COST_CENTERS';
export const REQUEST_ADD_COST_CENTER = '@masterData/REQUEST_ADD_COST_CENTER';
export const REQUEST_ADD_EXPENSE_TYPE = '@masterData/REQUEST_ADD_EXPENSE_TYPE';
export const REQUEST_UPDATE_COST_CENTER = '@masterData/REQUEST_UPDATE_COST_CENTER';
export const REQUEST_UPDATE_EXPENSE_TYPE = '@masterData/REQUEST_UPDATE_EXPENSE_TYPE';

export const REQUEST_GET_TASK_TYPES = '@taskTypes/REQUEST_GET_TASK_TYPES';
export const REQUEST_GET_TASK_GROUPS = '@taskTypes/REQUEST_GET_TASK_GROUPS';
export const REQUEST_GET_TASK_TYPE = '@taskTypes/REQUEST_GET_TASK_TYPE';
export const REQUEST_ADD_TASK_TYPE = '@taskTypes/REQUEST_ADD_TASK_TYPE';
export const REQUEST_UPDATE_TASK_TYPE = '@taskTypes/REQUEST_UPDATE_TASK_TYPE';
export const REQUEST_SET_TASK_TYPE_ACTIVE = '@taskTypes/REQUEST_SET_TASK_TYPE_ACTIVE';
export const REQUEST_SET_TASK_TYPE_INACTIVE = '@taskTypes/REQUEST_SET_TASK_TYPE_INACTIVE';
export const CLEAR_TASK_TYPE = '@taskTypes/CLEAR_TASK_TYPE';