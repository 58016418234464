import { setData } from '../../store/reduxUtils';
import * as actionTypes from './Settings.action.types';
import apiCall from '../../store/apiCall';


// return {
//     type: actionTypes.REQUEST_GET_USERS,
//     payload: {
//         client: 'default',
//         request: {
//             url: '/user/list',
//             method: 'GET'
//         }
//     }
// };
// return (dispatch) => dispatch(API.Users.requestGetUsers(actionTypes.REQUEST_GET_USERS));

export function requestGetUsers() {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `user/list` },
            { errorMessageCallback: () => `Felhasználók lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_USERS, data)))
            .catch(() => { });
}

export function requestSetUserPrivileges(privileges, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `user/setPrivileges`, data: privileges },
            { successMessageCallback: () => "Módosítások elmentve" })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_SET_USER_PRIVILEGES, privileges));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestGetCompanies() {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `masterData/ListCompaniesWithParkings` },
            { errorMessageCallback: () => `Cégek és parkolók lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_COMPANIES, data)))
            .catch(() => { });
}

export function requestAddCompany(company, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `masterData/AddCompany`, data: company },
            {
                successMessageCallback: () => "Cég sikeresen hozzáadva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidName": return `Ilyen nevű cég már létezik`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_ADD_COMPANY, company));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}


export function requestAddParking(parking, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `masterData/AddParking`, data: parking },
            {
                successMessageCallback: () => "Parkoló sikeresen hozzáadva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidName": return `Ilyen nevű parkoló már létezik`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_ADD_PARKING, parking));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestUpdateCompany(id, companyUpdate, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `masterData/UpdateCompany/${id}`, data: companyUpdate },
            {
                successMessageCallback: () => "Cég sikeresen módosítva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidName": return `Ilyen nevű cég már létezik`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_UPDATE_COMPANY, { id, ...companyUpdate }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestUpdateParking(id, parkingUpdate, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `masterData/UpdateParking/${id}`, data: parkingUpdate },
            {
                successMessageCallback: () => "Parkoló sikeresen módosítva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidName": return `Ilyen nevű parkoló már létezik`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_UPDATE_PARKING, { id, ...parkingUpdate }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestGetCostCenters() {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `masterData/ListCostCentersWithExpenseTypes` },
            { errorMessageCallback: () => `Költséghelyek és költségtípusok lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_COST_CENTERS, data)))
            .catch(() => { });
}

export function requestAddCostCenter(costCenter, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `masterData/AddCostCenter`, data: costCenter },
            {
                successMessageCallback: () => "Költséghely sikeresen hozzáadva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidName": return `Ilyen nevű költséghely már létezik`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_ADD_COST_CENTER, costCenter));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestAddExpenseType(expenseType, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `masterData/AddExpenseType`, data: expenseType },
            {
                successMessageCallback: () => "Költségtípus sikeresen hozzáadva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidName": return `Ilyen nevű költségtípus már létezik`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_ADD_EXPENSE_TYPE, expenseType));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestUpdateCostCenter(id, costCenterUpdate, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `masterData/UpdateCostCenter/${id}`, data: costCenterUpdate },
            {
                successMessageCallback: () => "Költséghely sikeresen módosítva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidName": return `Ilyen nevű költséghely már létezik`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_UPDATE_COST_CENTER, { id, ...costCenterUpdate }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestUpdateExpenseType(id, expenseTypeUpdate, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `masterData/UpdateExpenseType/${id}`, data: expenseTypeUpdate },
            {
                successMessageCallback: () => "Költségtípus sikeresen módosítva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "InvalidName": return `Ilyen nevű költségtípus már létezik`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_UPDATE_EXPENSE_TYPE, { id, ...expenseTypeUpdate }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestGetTaskTypes() {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskType/ListTypes` },
            { errorMessageCallback: () => `Feladattípusok lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_TASK_TYPES, data)))
            .catch(() => { });
}

export function requestGetTaskGroups() {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskType/ListGroups` },
            { errorMessageCallback: () => `Feladat csoportok lekérése sikertelen` })
            .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_TASK_GROUPS, data)))
            .catch(() => { });
}

export function requestGetTaskType(id, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'get', endpoint: `taskType/GetType/${id}` },
            { errorMessageCallback: () => `Feladattípus lekérése sikertelen` })
            .then(({ data }) => {
                dispatch(setData(actionTypes.REQUEST_GET_TASK_TYPE, data));
                if (onSuccess) onSuccess(data);
            })
            .catch(() => { });
}

export function requestAddTaskType(taskType, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `taskType/NewType`, data: taskType },
            {
                successMessageCallback: () => "Feladattípus sikeresen hozzáadva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "DuplicateName": return `Ilyen nevű feladattípus már létezik`;
                        case "DuplicateFieldName": return `Nem lehetnek ugyanolyan nevű mezők`;
                        case "DuplicateStateName": return `Nem lehetnek ugyanolyan nevű állapotok`;
                        case "MissingCategory": return `Nem minden sávhoz tartozik állapot.`;
                        case "InvalidPrimaryStates": return `Minden kategóriában pontosan egy első állapotnak kell lennie`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_ADD_TASK_TYPE, taskType));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestUpdateTaskType(taskType, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'post', endpoint: `taskType/NewVersion`, data: taskType },
            {
                successMessageCallback: () => "Feladattípus sikeresen módosítva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        case "DuplicateName": return `Ilyen nevű feladattípus már létezik`;
                        case "DirtyUpdate": return `Időközben más módosította a feladattípust`;
                        case "DuplicateFieldName": return `Nem lehetnek ugyanolyan nevű mezők`;
                        case "DuplicateStateName": return `Nem lehetnek ugyanolyan nevű állapotok`;
                        case "MissingCategory": return `Nem minden sávhoz tartozik állapot.`;
                        case "InvalidPrimaryStates": return `Minden kategóriában pontosan egy első állapotnak kell lennie`;
                        default: return `Váratlan hiba`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_UPDATE_TASK_TYPE, taskType));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestSetTaskTypeActive(id, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `taskType/SetActive/${id}` },
            {
                successMessageCallback: () => "Feladattípus sikeresen aktiválva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        default: return `Feladattípus aktiválása sikertelen`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_SET_TASK_TYPE_ACTIVE, { id }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function requestSetTaskTypeInactive(id, onSuccess) {
    return (dispatch, getState) =>
        apiCall(
            dispatch, getState, { method: 'put', endpoint: `taskType/SetInactive/${id}` },
            {
                successMessageCallback: () => "Feladattípus sikeresen deaktiválva",
                errorMessageCallback: (error) => {
                    switch (error.code) {
                        default: return `Feladattípus deaktiválása sikertelen`;
                    }
                }
            })
            .then(() => {
                dispatch(setData(actionTypes.REQUEST_SET_TASK_TYPE_INACTIVE, { id }));
                if (onSuccess) onSuccess();
            })
            .catch(() => { });
}

export function clearTaskType() {
    return (dispatch) => dispatch({ type: actionTypes.CLEAR_TASK_TYPE });
}