import React, { useState, useEffect } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./Settings.module.css";
import sceneStyles from "../Scenes.module.css";
import { privileges, stateCategories, fieldTypes } from "../../Enums";
import { rgbaToHex, hexToRgba } from "../../Utils";
import { Typography } from "@progress/kendo-react-common";
import { Checkbox, ColorPicker, Input } from "@progress/kendo-react-inputs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { Field, Form, FormElement } from "@progress/kendo-react-form";


const fieldTypeListData = Object.keys(fieldTypes).map(type => ({ value: type, text: fieldTypes[type] }));
const fieldTypeTexts = fieldTypeListData.map(type => type.text);

const stateCategoryListData = Object.keys(stateCategories).map(category => ({ value: category, text: stateCategories[category] }));
const stateCategoryTexts = stateCategoryListData.map(category => category.text);

const privilegeListData = Object.keys(privileges).map(p => ({ value: p, text: privileges[p] })).slice(1); // Drop 'none' privilege


export const TaskTypeEdit = (props) => {
    const {
        taskGroups,
        taskType,
        requestGetTaskTypes,
        requestGetTaskGroups,
        requestAddTaskType,
        requestUpdateTaskType,
        handleCloseEditScreen,
        isNewTaskType,
        setLeaveDialogData
    } = props;

    const [name, setName] = useState('');
    const [color, setColor] = useState("#f36618");
    const [group, setGroup] = useState('');
    const [privilege, setPrivilege] = useState(privilegeListData[0].value);
    const [customFields, setCustomFields] = useState([]);
    const [states, setStates] = useState([]);

    const [activeDragItem, setActiveDragItem] = useState(null);

    const [selectedFieldsState, setSelectedFieldsState] = useState({});
    const [selectedStatesState, setSelectedStatesState] = useState({});

    const [fieldDialogData, setFieldDialogData] = useState({ visible: false, field: null });
    const [stateDialogData, setStateDialogData] = useState({ visible: false, state: null });

    useEffect(() => {
        setLeaveDialogData({ shouldShow: true, title: "Biztosan kilép a módosításokból?", message: "Feladattípus módosításai elvesznek." });
        
        return () => {
            setLeaveDialogData({ shouldShow: false });
        };
    }, []);

    useEffect(() => {
        if (!isNewTaskType && taskType) {
            setName(taskType.name);
            setColor(taskType.color);
            setGroup(taskType.group);
            setPrivilege(taskType.privilege);
            setCustomFields(taskType.customFields);
            setStates(taskType.states);
        }
    }, [taskType]);

    const validTaskType = () => {
        if (name.length === 0)
            return false;
        if (group.length === 0)
            return false;

        if (!validStates())
            return false;

        return true;
    };

    const validStates = () => {
        const primaryCounts = Object.keys(stateCategories).reduce((acc, value) => {
            return { ...acc, [value]: 0 };
        }, {});

        states.filter(state => state.isPrimary).forEach(state => primaryCounts[state.category]++);

        if (Object.values(primaryCounts).every(count => count === 1))
            return true;

        return false;
    };

    const handleSaveTaskType = () => {
        let newTaskType = {
            name,
            color,
            group,
            privilege,
            customFields,
            states
        };
        if (isNewTaskType) {
            requestAddTaskType(newTaskType, () => {
                requestGetTaskTypes();
                requestGetTaskGroups();
            });
        } else {
            newTaskType.typeId = taskType.typeId;
            newTaskType.version = taskType.version;
            requestUpdateTaskType(newTaskType, () => {
                requestGetTaskTypes();
                requestGetTaskGroups();
            });
        }
        handleCloseEditScreen();
    };

    const handleNewField = () => {
        setSelectedFieldsState({});
        setFieldDialogData({ visible: true, field: null });
    };

    const handleRemoveFields = () => {
        setCustomFields(customFields.filter(field => !selectedFieldsState[field.name]));
        setSelectedFieldsState({});
    };

    const renderDraggableRow = (row, rowProps) => <tr
        onDragOver={(e) => {
            reorder(rowProps.dataItem);
            e.preventDefault();
            e.dataTransfer.dropEffect = "copy";
        }}
        onDragStart={(e) => {
            setActiveDragItem(customFields.find(field => field.name === rowProps.dataItem.name));
            e.dataTransfer.setData("dragging", "");
        }}
        onDragEnd={(e) => {
            setActiveDragItem(null);
        }}

        draggable={true}
        style={{ cursor: "move" }} {...row.props}
    />;

    const renderDragHandle = (props) => {
        return <td>
            <span
                className="fa-solid fa-bars"
                style={{
                    cursor: "move"
                }}

            />
        </td>;
    };

    const renderFieldName = (props) => {
        // {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }} // Needed for selection
        return <td
            className={props.isSelected ? "k-state-selected" : ""}
        >
            <a href="/"
                draggable={false}
                onClick={e => {
                    e.preventDefault();
                    setSelectedFieldsState({});
                    setFieldDialogData({ visible: true, field: props.dataItem });
                }}>{props.dataItem[props.field]}
            </a>
        </td>;
    };

    const renderFieldType = (props) =>
        <td
            className={props.isSelected ? "k-state-selected" : ""}
        >
            {fieldTypeListData.find(t => t.value === props.dataItem[props.field]).text}
        </td>;

    const renderFieldMandatory = (props) => <td
        className={props.isSelected ? "k-state-selected" : ""}
    >
        {props.dataItem[props.field] ? "Igen" : "Nem"}
    </td>;

    const fieldNameValidator = (value) => {
        if (value.length === 0)
            return "Név nem lehet üres";
        if (fieldDialogData.field && value === fieldDialogData.field.name)
            return "";
        if (customFields.map(field => field.name).includes(value))
            return "Van már ilyen nevű mező";
        return "";
    };

    const reorder = (droppedItem) => {
        let dataItem = customFields.find(field => field.name === droppedItem.name);
        if (!activeDragItem || activeDragItem === dataItem) {
            return;
        }
        let reorderedData = customFields.slice();
        const prevIndex = reorderedData.findIndex((p) => p === activeDragItem);
        const nextIndex = reorderedData.findIndex((p) => p === dataItem);
        reorderedData.splice(prevIndex, 1);
        reorderedData.splice(nextIndex, 0, activeDragItem);
        setCustomFields(reorderedData);
    };

    const onFieldSelectionChange = (e) => {
        const newSelectedState = getSelectedState({
            event: e,
            selectedState: selectedFieldsState,
            dataItemKey: "name",
        });
        setSelectedFieldsState(newSelectedState);
    };

    const onFieldHeaderSelectionChange = (e) => {
        const newSelectedState = {};
        e.dataItems.forEach((item) => {
            newSelectedState[item.name] = e.syntheticEvent.target.checked;
        });
        setSelectedFieldsState(newSelectedState);
    };

    const handleNewState = () => {
        setSelectedStatesState({});
        setStateDialogData({ visible: true, state: null });
    };

    const handleRemoveStates = () => {
        setStates(states.filter(state => !selectedStatesState[state.name]));
        setSelectedStatesState({});
    };

    const renderStateName = (props) =>
        <td
            className={props.isSelected ? "k-state-selected" : ""}
        >
            <a href="/" onClick={e => {
                e.preventDefault();
                setSelectedStatesState({});
                setStateDialogData({ visible: true, state: props.dataItem });
            }}>{props.dataItem[props.field]}
            </a>
        </td>;

    const renderStateCategory = (props) =>
        <td
            className={props.isSelected ? "k-state-selected" : ""}
        >
            {stateCategoryListData.find(c => c.value === props.dataItem[props.field]).text}
        </td>;

    const renderStatePrimary = (props) => <td
        className={props.isSelected ? "k-state-selected" : ""}
    >
        {props.dataItem[props.field] ? "Igen" : "Nem"}
    </td>;

    const stateNameValidator = (value) => {
        if (value.length === 0)
            return "Név nem lehet üres";
        if (stateDialogData.state && value === stateDialogData.state.name)
            return "";
        if (states.map(state => state.name).includes(value))
            return "Van már ilyen nevű állapot";
        return "";
    };

    const onStateSelectionChange = (e) => {
        const newSelectedState = getSelectedState({
            event: e,
            selectedState: selectedStatesState,
            dataItemKey: "name",
        });
        setSelectedStatesState(newSelectedState);
    };

    const onStateHeaderSelectionChange = (e) => {
        const newSelectedState = {};
        e.dataItems.forEach((item) => {
            newSelectedState[item.name] = e.syntheticEvent.target.checked;
        });
        setSelectedStatesState(newSelectedState);
    };

    return (
        <div className={styles.taskTypeEditContainer}>
            <div className={styles.buttonRow}>
                <Button onClick={handleCloseEditScreen}>Vissza</Button>
                <Button onClick={handleSaveTaskType} themeColor={"primary"} disabled={!validTaskType()}>Mentés</Button>
            </div>
            <div className={styles.taskTypeFormContainer}>
                <Typography.p>Név*</Typography.p>
                <Input value={name} onChange={(e) => setName(e.value)} />
                <Typography.p>Szín</Typography.p>
                <ColorPicker gradientSettings={{ opacity: false }} view="gradient" value={hexToRgba(color)} onChange={(e) => setColor(rgbaToHex(e.value))} defaultValue={color} />
                <Typography.p>Csoport*</Typography.p>
                <AutoComplete className={styles.shortField} value={group} onChange={(e) => setGroup(e.value)} data={taskGroups} />
                <Typography.p>Jogosultsági szint</Typography.p>
                <DropDownList
                    className={styles.shortField}
                    data={privilegeListData}
                    textField="text"
                    value={privilegeListData.find(p => p.value === privilege)}
                    onChange={(e) => setPrivilege(e.value.value)} />
            </div>
            <div>
                <div className={styles.taskTypeTableHeader}>
                    <Typography.h6>Mezők</Typography.h6>
                    <div>
                        <Button className={styles.tableButton} fillMode="flat" iconClass="fa-solid fa-lg fa-minus" themeColor="primary"
                            disabled={Object.values(selectedFieldsState).filter(item => item === true).length === 0} onClick={handleRemoveFields}
                        />
                        <Button className={styles.tableButton} fillMode="flat" iconClass="fa-solid fa-lg fa-plus" themeColor="primary" onClick={handleNewField} />
                    </div>
                </div>
                <Grid
                    className={styles.taskTypeTable}
                    data={customFields.map(item => ({ ...item, selected: selectedFieldsState[item.name] }))}
                    selectedField="selected"
                    selectable={true}
                    onSelectionChange={onFieldSelectionChange}
                    onHeaderSelectionChange={onFieldHeaderSelectionChange}
                    rowRender={renderDraggableRow}
                >
                    <GridColumn title="" width="50px" cell={renderDragHandle} />
                    <GridColumn
                        field="selected"
                        width="50px"
                        headerSelectionValue={customFields.length !== 0 && customFields.length === Object.values(selectedFieldsState).filter(item => item === true).length}
                    />
                    <GridColumn field="name" title="Név" cell={renderFieldName} />
                    <GridColumn width={150} field="type" title="Típus" cell={renderFieldType} />
                    <GridColumn width={150} field="mandatory" title="Kötelező" cell={renderFieldMandatory} />
                </Grid>
            </div>
            <div>
                <div className={styles.taskTypeTableHeader}>
                    <div className={styles.labelTooltip}>
                        <Typography.h6>Állapotok*</Typography.h6>
                        <Typography.p style={{ color: !validStates() ? "red" : null }}>(Minden sávhoz pontosan egy 'első' állapotnak kell tartoznia)</Typography.p>
                    </div>
                    <div>
                        <Button className={styles.tableButton} fillMode="flat" iconClass="fa-solid fa-lg fa-minus" themeColor="primary"
                            disabled={Object.values(selectedStatesState).filter(item => item === true).length === 0} onClick={handleRemoveStates}
                        />
                        <Button className={styles.tableButton} fillMode="flat" iconClass="fa-solid fa-lg fa-plus" themeColor="primary" onClick={handleNewState} />
                    </div>
                </div>
                <Grid
                    className={styles.taskTypeTable}
                    data={states.map(item => ({ ...item, selected: selectedStatesState[item.name] }))}
                    selectedField="selected"
                    selectable={true}
                    onSelectionChange={onStateSelectionChange}
                    onHeaderSelectionChange={onStateHeaderSelectionChange}
                >
                    <GridColumn
                        field="selected"
                        width="50px"
                        headerSelectionValue={states.length !== 0 && states.length === Object.values(selectedStatesState).filter(item => item === true).length}
                    />
                    <GridColumn field="name" title="Név" cell={renderStateName} />
                    <GridColumn width={150} field="category" title="Sáv" cell={renderStateCategory} />
                    <GridColumn width={150} field="isPrimary" title="Első" cell={renderStatePrimary} />
                </Grid>
            </div>
            {fieldDialogData.visible && (
                <Dialog
                    width={400}
                    title={"Új mező hozzáadása"}
                    onClose={() => setFieldDialogData({ visible: false })}
                    autoFocus={true}
                >
                    <Form
                        onSubmit={(values, e) => {
                            if (fieldDialogData.field) {
                                const idx = customFields.findIndex(field => field.name === fieldDialogData.field.name);
                                setCustomFields([
                                    ...customFields.slice(0, idx),
                                    { name: values.name, type: fieldTypeListData.find(type => type.text === values.type).value, mandatory: values.mandatory },
                                    ...customFields.slice(idx + 1)
                                ]);
                            } else
                                setCustomFields([...customFields, { name: values.name, type: fieldTypeListData.find(type => type.text === values.type).value, mandatory: values.mandatory }]);
                            setFieldDialogData({ visible: false });
                        }}
                        initialValues={{
                            name: fieldDialogData.field?.name ?? "",
                            type: fieldDialogData.field ? fieldTypeListData.find(type => type.value === fieldDialogData.field.type)?.text : fieldTypeTexts[0],
                            mandatory: fieldDialogData.field?.mandatory ?? false
                        }}
                        render={(formProps) => (
                            <FormElement className={sceneStyles.dialogContainer}>
                                <div className={sceneStyles.dialogForm}>
                                    <Field
                                        name="name"
                                        label="Név"
                                        validator={fieldNameValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        name="type"
                                        label="Típus"
                                        data={fieldTypeTexts}
                                        validator={(value) => fieldTypeTexts.includes(value) ? "" : "Nem megfelelő típus"}
                                        required={true}
                                        component={DropDownList}
                                    />
                                    <Field
                                        name="mandatory"
                                        label="Kötelező"
                                        labelPlacement={"after"}
                                        component={Checkbox}
                                    />
                                </div>
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        {fieldDialogData.field ? "Módosítás" : "Hozzáadás"}
                                    </Button>
                                </div>
                            </FormElement>
                        )}
                    />
                </Dialog>
            )}
            {stateDialogData.visible && (
                <Dialog
                    width={400}
                    title={"Új állapot hozzáadása"}
                    onClose={() => setStateDialogData({ visible: false })}
                    autoFocus={true}
                >
                    <Form
                        onSubmit={(values, e) => {
                            if (stateDialogData.state) {
                                const idx = states.findIndex(state => state.name === stateDialogData.state.name);
                                setStates([
                                    ...states.slice(0, idx),
                                    { name: values.name, category: stateCategoryListData.find(category => category.text === values.category).value, isPrimary: values.isPrimary },
                                    ...states.slice(idx + 1)
                                ]);
                            } else
                                setStates([...states, { name: values.name, category: stateCategoryListData.find(category => category.text === values.category).value, isPrimary: values.isPrimary }]);
                            setStateDialogData({ visible: false });
                        }}
                        initialValues={{
                            name: stateDialogData.state?.name ?? "",
                            category: stateDialogData.state ? stateCategoryListData.find(category => category.value === stateDialogData.state.category)?.text : stateCategoryTexts[0],
                            isPrimary: stateDialogData.state?.isPrimary ?? false
                        }}
                        render={(formProps) => (
                            <FormElement className={sceneStyles.dialogContainer}>
                                <div className={sceneStyles.dialogForm}>
                                    <Field
                                        name="name"
                                        label="Név"
                                        validator={stateNameValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        name="category"
                                        label="Sáv"
                                        data={stateCategoryTexts}
                                        validator={(value) => stateCategoryTexts.includes(value) ? "" : "Nem megfelelő sáv"}
                                        required={true}
                                        component={DropDownList}
                                    />
                                    <Field
                                        name="isPrimary"
                                        label="Első"
                                        labelPlacement={"after"}
                                        component={Checkbox}
                                    />
                                </div>
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        {stateDialogData.state ? "Módosítás" : "Hozzáadás"}
                                    </Button>
                                </div>
                            </FormElement>
                        )}
                    />
                </Dialog>
            )}
        </div >);
};