import React, { useState, useEffect } from "react";
import styles from "./Settings.module.css";
import sceneStyles from "../Scenes.module.css";
import { connect } from 'react-redux';
import connector from './Settings.connector.js';
import { UserSettings } from "./UserSettings";
import { MasterDataSettings } from "./MasterDataSettings";
import { TaskTypeSettings } from "./TaskTypeSettings";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";

export const Settings = (props) => {
    const {
        users,
        companies,
        costCenters,
        taskTypes,
        taskGroups,
        taskType,
        requestGetUser,
        requestGetUsers,
        requestSetUserPrivileges,
        requestGetCompanies,
        requestAddCompany,
        requestAddParking,
        requestUpdateCompany,
        requestUpdateParking,
        requestGetCostCenters,
        requestAddCostCenter,
        requestAddExpenseType,
        requestUpdateCostCenter,
        requestUpdateExpenseType,
        requestGetTaskTypes,
        requestGetTaskType,
        requestGetTaskGroups,
        requestAddTaskType,
        requestUpdateTaskType,
        requestSetTaskTypeActive,
        requestSetTaskTypeInactive,
        clearTaskType
    } = props;

    const [selectedMenu, setSelectedMenu] = useState(0);
    const [leaveDialogData, setLeaveDialogData] = useState({ shouldShow: false, title: '', message: '' });
    const [leaveDialogControl, setLeaveDialogControl] = useState({ visible: false, yesCallback: () => { } });

    useEffect(() => {
        requestGetUsers();
    }, [requestGetUsers]);

    useEffect(() => {
        requestGetCompanies();
    }, [requestGetCompanies]);

    useEffect(() => {
        requestGetCostCenters();
    }, [requestGetCostCenters]);

    useEffect(() => {
        requestGetTaskTypes();
    }, [requestGetTaskTypes]);

    useEffect(() => {
        requestGetTaskGroups();
    }, [requestGetTaskGroups]);

    const closeLeaveDialog = () => setLeaveDialogControl({ visible: false });

    return (
        <div className={sceneStyles.container}>
            <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
            />
            <div className={styles.menuRow}>
                <a href="/" onClick={e => {
                    e.preventDefault();
                    if (leaveDialogData.shouldShow)
                        setLeaveDialogControl({ visible: true, yesCallback: () => setSelectedMenu(0) });
                    else
                        setSelectedMenu(0);
                }}>Felhasználókezelés</a> |{" "}
                <a href="/" onClick={e => {
                    e.preventDefault();
                    if (leaveDialogData.shouldShow)
                        setLeaveDialogControl({ visible: true, yesCallback: () => setSelectedMenu(1) });
                    else
                        setSelectedMenu(1);
                }}>Pénzügyi törzsadatok</a> |{" "}
                <a href="/" onClick={e => {
                    e.preventDefault();
                    if (leaveDialogData.shouldShow)
                        setLeaveDialogControl({ visible: true, yesCallback: () => setSelectedMenu(2) });
                    else
                        setSelectedMenu(2);
                }}>Feladat típusok</a>
            </div>
            <div className={styles.contentContainer}>
                {selectedMenu === 0 && <UserSettings
                    users={users}
                    requestGetUser={requestGetUser}
                    requestGetUsers={requestGetUsers}
                    requestSetUserPrivileges={requestSetUserPrivileges}
                />}
                {selectedMenu === 1 &&
                    <MasterDataSettings
                        companies={companies}
                        costCenters={costCenters}
                        requestGetCompanies={requestGetCompanies}
                        requestAddCompany={requestAddCompany}
                        requestAddParking={requestAddParking}
                        requestUpdateCompany={requestUpdateCompany}
                        requestUpdateParking={requestUpdateParking}
                        requestGetCostCenters={requestGetCostCenters}
                        requestAddCostCenter={requestAddCostCenter}
                        requestAddExpenseType={requestAddExpenseType}
                        requestUpdateCostCenter={requestUpdateCostCenter}
                        requestUpdateExpenseType={requestUpdateExpenseType}
                    />
                }
                {selectedMenu === 2 && <TaskTypeSettings
                    taskTypes={taskTypes}
                    taskGroups={taskGroups}
                    taskType={taskType}
                    requestGetTaskTypes={requestGetTaskTypes}
                    requestGetTaskGroups={requestGetTaskGroups}
                    requestGetTaskType={requestGetTaskType}
                    requestAddTaskType={requestAddTaskType}
                    requestUpdateTaskType={requestUpdateTaskType}
                    requestSetTaskTypeActive={requestSetTaskTypeActive}
                    requestSetTaskTypeInactive={requestSetTaskTypeInactive}
                    clearTaskType={clearTaskType}
                    setLeaveDialogData={setLeaveDialogData}
                />}
            </div>
            {leaveDialogControl.visible ? (<Dialog
                width={400}
                title={leaveDialogData.title}
                onClose={closeLeaveDialog}
                autoFocus={true}
            >
                <Typography.p>{leaveDialogData.message} </Typography.p>
                <DialogActionsBar>
                    <div className={sceneStyles.dialogActionsBar}>
                        <Button
                            onClick={closeLeaveDialog}
                        >
                            Nem
                        </Button>
                        <Button
                            themeColor="primary"
                            onClick={() => {
                                leaveDialogControl.yesCallback();
                                closeLeaveDialog();
                            }}
                        >
                            Igen
                        </Button>
                    </div>
                </DialogActionsBar>
            </Dialog>) : null}
        </div>
    );
};

export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(Settings);
