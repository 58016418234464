import { setData } from '../store/reduxUtils';
import * as actionTypes from './Auth.action.types';
import * as responseActionTypes from '../store/Response.action.types';
import apiCall from '../store/apiCall';

export function requestGetUser() {
    return (dispatch, getState) =>
        {
            dispatch({ type: responseActionTypes.SET_LOADING });
            apiCall(
                dispatch, getState, { method: 'get', endpoint: `user/getUserInfo` },
                { errorMessageCallback: () => `Felhasználó adatának lekérése sikertelen` })
                .then(({ data }) => dispatch(setData(actionTypes.REQUEST_GET_USER, data)))
                .catch(() => { })
                .finally(() => dispatch({ type: responseActionTypes.CLEAR_LOADING }));
        };
}