/* eslint-disable import/no-anonymous-default-export */
import * as settingActions from './Settings.action.js';
import * as authActions from '../../auth/Auth.action.js';

export default {
    mapStateToProps: (state) => {
        return {
            users: state.SettingsReducer.users,
            companies: state.SettingsReducer.companies,
            costCenters: state.SettingsReducer.costCenters,
            taskTypes: state.SettingsReducer.taskTypes,
            taskGroups: state.SettingsReducer.taskGroups,
            taskType: state.SettingsReducer.taskType
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            requestGetUser: () => dispatch(authActions.requestGetUser()),
            requestGetUsers: () => dispatch(settingActions.requestGetUsers()),
            requestSetUserPrivileges: (privileges, onSuccess) => dispatch(settingActions.requestSetUserPrivileges(privileges, onSuccess)),
            requestGetCompanies: () => dispatch(settingActions.requestGetCompanies()),
            requestAddCompany: (company, onSuccess) => dispatch(settingActions.requestAddCompany(company, onSuccess)),
            requestAddParking: (parking, onSuccess) => dispatch(settingActions.requestAddParking(parking, onSuccess)),
            requestUpdateCompany: (id, companyUpdate, onSuccess) => dispatch(settingActions.requestUpdateCompany(id, companyUpdate, onSuccess)),
            requestUpdateParking: (id, parkingUpdate, onSuccess) => dispatch(settingActions.requestUpdateParking(id, parkingUpdate, onSuccess)),
            requestGetCostCenters: () => dispatch(settingActions.requestGetCostCenters()),
            requestAddCostCenter: (costCenter, onSuccess) => dispatch(settingActions.requestAddCostCenter(costCenter, onSuccess)),
            requestAddExpenseType: (expenseType, onSuccess) => dispatch(settingActions.requestAddExpenseType(expenseType, onSuccess)),
            requestUpdateCostCenter: (id, costCenterUpdate, onSuccess) => dispatch(settingActions.requestUpdateCostCenter(id, costCenterUpdate, onSuccess)),
            requestUpdateExpenseType: (id, expenseTypeUpdate, onSuccess) => dispatch(settingActions.requestUpdateExpenseType(id, expenseTypeUpdate, onSuccess)),
            requestGetTaskTypes: () => dispatch(settingActions.requestGetTaskTypes()),
            requestGetTaskGroups: () => dispatch(settingActions.requestGetTaskGroups()),
            requestGetTaskType: (id, onSuccess) => dispatch(settingActions.requestGetTaskType(id, onSuccess)),
            requestAddTaskType: (taskType, onSuccess) => dispatch(settingActions.requestAddTaskType(taskType, onSuccess)),
            requestUpdateTaskType: (taskType, onSuccess) => dispatch(settingActions.requestUpdateTaskType(taskType, onSuccess)),
            requestSetTaskTypeActive: (id, onSuccess) => dispatch(settingActions.requestSetTaskTypeActive(id, onSuccess)),
            requestSetTaskTypeInactive: (id, onSuccess) => dispatch(settingActions.requestSetTaskTypeInactive(id, onSuccess)),
            clearTaskType: () => dispatch(settingActions.clearTaskType())
        };
    }
};
