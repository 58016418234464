export const REQUEST_GET_TASKS = '@tasks/REQUEST_GET_TASKS';
export const REQUEST_GET_NEW_TASK_TYPES = '@tasks/REQUEST_GET_NEW_TASKS';
export const REQUEST_SET_TASK_STATUS = '@tasks/REQUEST_SET_TASK_STATUS';
export const REQUEST_GET_ALL_USERS = '@tasks/REQUEST_GET_ALL_USERS';
export const REQUEST_GET_USERS_BY_MIN_PRIVILEGE = '@tasks/REQUEST_GET_USERS_BY_MIN_PRIVILEGE';
export const REQUEST_GET_TASK_TYPE = '@taskTypes/REQUEST_GET_TASK_TYPE';
export const REQUEST_CREATE_TASK = '@tasks/REQUEST_CREATE_TASK';
export const REQUEST_UPDATE_TASK = '@tasks/REQUEST_UPDATE_TASK';
export const REQUEST_GET_TASK = '@tasks/REQUEST_GET_TASK';
export const REQUEST_REMOVE_TASK = '@tasks/REQUEST_REMOVE_TASK';
export const REQUEST_GET_TASK_ISFOLLOW = '@tasks/REQUEST_GET_TASK_ISFOLLOW';
export const REQUEST_SET_TASK_FOLLOW = '@tasks/REQUEST_SET_TASK_FOLLOW';
export const REQUEST_SET_TASK_UNFOLLOW = '@tasks/REQUEST_SET_TASK_UNFOLLOW';
export const REQUEST_GET_TASK_HISTORY = '@tasks/REQUEST_GET_TASK_HISTORY';
export const REQUEST_GET_TASK_ATTACHMENTS = '@tasks/REQUEST_GET_TASK_ATTACHMENTS';
export const REQUEST_GET_TASK_ATTACHMENT_LINK = '@tasks/REQUEST_GET_TASK_ATTACHMENT_LINK';
export const REQUEST_ADD_TASK_ATTACHMENT = '@tasks/REQUEST_ADD_TASK_ATTACHMENT';
export const REQUEST_REMOVE_TASK_ATTACHMENT = '@tasks/REQUEST_REMOVE_TASK_ATTACHMENT';
export const CLEAR_TASK = '@tasks/CLEAR_TASK';
export const CLEAR_TASK_ATTACHMENT_LINK = '@tasks/CLEAR_TASK_ATTACHMENT_LINK';