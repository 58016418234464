import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

const reactPlugin = new ReactPlugin();
const appInsightsConfig = {
    connectionString: "InstrumentationKey=febbdf4b-66a1-4324-b829-c947dbe604aa;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/",
    extensions: [reactPlugin],
    extensionConfig: {},
    enableAutoRouteTracking: true,
    autoTrackPageVisitTime: true
};

export {reactPlugin, appInsightsConfig };
