import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { useDispatch, useSelector } from 'react-redux';
import createRootReducer from "./Root.reducer";

const axiosOptions = {
    returnRejectedPromiseOnError: true,
    interceptors: {

    }
};

export const createReduxStore = (initialConfig) => {
    const middlewares = [thunk];

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(createRootReducer(), { ConfigReducer: initialConfig }, composeEnhancers(applyMiddleware(...middlewares)));
};

export const useAppSelector = useSelector;
export const useAppDispatch = () => useDispatch();