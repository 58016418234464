import React, { useEffect, useState } from 'react';
import './App.scss';
import { Login } from "./components/login/Login";
import { Provider } from 'react-redux';
import { createReduxStore } from './store/Store';
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import caGregorian from "cldr-dates-full/main/hu/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/hu/dateFields.json";
import numbers from "cldr-numbers-full/main/hu/numbers.json";
import { IntlProvider, LocalizationProvider, load, loadMessages } from '@progress/kendo-react-intl';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./authConfig";
import { reactPlugin, appInsightsConfig } from './applicationInsightConfig';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import huMessages from "./hu.json";


load(likelySubtags, weekData, numbers, caGregorian, dateFields);
loadMessages(huMessages, "hu");
function App() {
  const [msalInstance, setMsalInstance] = useState();
  const [store, setStore] = useState();

  const setData = (msalConfig, loginRequest, appInsights) => {
    setStore(createReduxStore({ config: { msalConfig, loginRequest, appInsights } }));
    setMsalInstance(new PublicClientApplication(msalConfig));
  };

  // Load config values dynamically
  useEffect(() => {
    const appInsights = new ApplicationInsights({ config: appInsightsConfig });

    if (process.env.NODE_ENV === "development") {
      appInsights.loadAppInsights();
      appInsights.trackPageView();
      setData(msalConfig, loginRequest, reactPlugin);
    } else {
      fetch('../../configValues.json')
        .then(response => response.json())
        .then(configValues => {
          appInsights.connectionString = configValues.appInsightsConnectionString;
          appInsights.loadAppInsights();
          appInsights.trackPageView();
          msalConfig.auth = configValues.auth;
          loginRequest.scopes = configValues.scopes;
          setData(msalConfig, loginRequest, reactPlugin);
        }).catch(e => console.log("Can't load configValues.json"));
    }
  }, []);

  return (
    (store && msalInstance) ? (
      <AppInsightsContext.Provider value={reactPlugin}>
        <MsalProvider instance={msalInstance}>
          <LocalizationProvider language="hu">
            <IntlProvider locale="hu">
              <Provider store={store}>
                <Login />
              </Provider>
            </IntlProvider>
          </LocalizationProvider>
        </MsalProvider>
      </AppInsightsContext.Provider>) : null
  );
}

export default App;
