import * as actionTypes from "./Expenses.action.types";

export const initialState = {
    expenses: [],
    years: [],
    statistics: [],
    isLoading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
    case actionTypes.REQUEST_GET_EXPENSES: {
        return {
            ...state,
            expenses: action.payload.data
        };
    }
    case actionTypes.REQUEST_GET_YEARS: {
        return {
            ...state,
            years: action.payload.data
        };
    }
    case actionTypes.REQUEST_GET_STATISTICS: {
        return {
            ...state,
            statistics: action.payload.data
        };
    }
    default:
        return state;
    }
}
