export const authorizeTasks = (user) => user ? user.privilege === 'Director' || user.privilege === 'Management' || user.privilege === 'Employee' : false;
export const authorizeIncomes = (user) => user ? user.privilege === 'Director' : false;
export const authorizeExpenses = (user) => user ? user.privilege !== "None" && user.expensePermission !== "None" : false;
export const authorizeSettings = (user) => user ? user.privilege === 'Director' : false;


// msalConfig auth and loginRequest scopes here are only used in development mode
// In staging and production we use configValues.json

export const msalConfig = {
    auth: {
        "clientId": "b8bdb5dc-2f83-40fe-b90d-f0ecf1a061c7",
        "authority": "https://login.microsoftonline.com/586f2aaf-0bc7-45d8-bf3c-505459605a39",
        "redirectUri": "http://localhost:3000"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["api://878fcfa4-51cf-4f08-ac45-b6dd09e243bd/BackOffice.ReadWrite"]
};
