/* eslint-disable import/no-anonymous-default-export */
import * as incomeActions from './Incomes.action.js';
import { convertToLocalTime, convertToDottedDateTimeString } from '../../Utils.js';
import { requestGetCompanies } from '../settings/Settings.action.js';

export default {
    mapStateToProps: (state) => {
        return {
            incomes: state.IncomesReducer.incomes.map(i => (
                {
                    ...i,
                    date: new Date(i.date),
                    month: 'M' + i.month,
                    uploadDate: convertToLocalTime(new Date(i.uploadDate)),
                    comment: i.isActive ? i.comment : (i.comment ?? "") + ` Deaktiválva ${convertToDottedDateTimeString(convertToLocalTime(new Date(i.deactivateTime)))}-kor ${i.deactivateUser} által.`
                })),
            years: state.IncomesReducer.years,
            statistics: state.IncomesReducer.statistics,
            parkings: state.SettingsReducer.companies.map(c => c.parkings.map(p => p.name)).flat().sort()
        };
    },
    mapDispatchToProps: (dispatch) => {
        return {
            requestGetIncomes: (year) => dispatch(incomeActions.requestGetIncomes(year)),
            requestGetYears: () => dispatch(incomeActions.requestGetYears()),
            requestGetStatistics: (year) => dispatch(incomeActions.requestGetStatistics(year)),
            requestGetParkings: () => dispatch(requestGetCompanies()),
            requestPostUploadFile: (file, onSuccess) => dispatch(incomeActions.requestPostUploadFile(file, onSuccess)),
            requestAddIncome: (income, onSuccess) => dispatch(incomeActions.requestAddIncome(income, onSuccess)),
            requestDeactivate: (ids, onSuccess) => dispatch(incomeActions.requestDeactivate(ids, onSuccess))
        };
    }
};
