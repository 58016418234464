const timeOffset = new Date().getTimezoneOffset() * 60 * 1000;

export function convertToLocalTime(utcTime) {
    return new Date(utcTime - timeOffset);
}

export function convertToDashedDateString(date) {
    return date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : null;
}

export function convertToDottedDateString(date) {
    return date ? `${date.getFullYear()}. ${(date.getMonth() + 1).toString().padStart(2, '0')}. ${date.getDate().toString().padStart(2, '0')}.` : null;
}

export function convertToDottedDateTimeString(date) {
    return date ? `${date.getFullYear()}. ${(date.getMonth() + 1).toString().padStart(2, '0')}. ${date.getDate().toString().padStart(2, '0')}. ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}` : null;
}

export function fromDottedDateString(dateString) {
    return dateString ? new Date(dateString.slice(0, -1).replaceAll('. ', '-')) : null;
}

let timeoutId;
export function debounce(func, delay) {
    return function () {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(this, arguments);
        }, delay);
    };
}

// 'rgba(120, 81, 60, 1)' to '#78513c'
export const rgbaToHex = (rgbaString) => {
    return '#' + [...rgbaString.matchAll(/\d+/g)] // get the numbers from string
        .slice(0, 3) // drop alpha value
        .map(v => parseInt(v).toString(16).padStart(2, '0')) // to 2 character hex string
        .join(''); // join the values
};

// '#78513c' to 'rgba(120, 81, 60, 1)'
export const hexToRgba = (hexString) => {
    return "rgba(" + [...hexString.matchAll(/\w\w/g)] // get the two characters long hexes from string
        .map(v => parseInt(v, 16)) // convert to decimal ints
        .join(", ") + ', 1)'; // join with comma and end the string
};