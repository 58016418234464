import { combineReducers } from "redux";
import ResponseReducer from "./Response.reducer";
import TasksReducer from "../scenes/tasks/Tasks.reducer";
import SettingsReducer from "../scenes/settings/Settings.reducer";
import IncomesReducer from "../scenes/incomes/Incomes.reducer";
import ExpensesReducer from "../scenes/expenses/Expenses.reducer";
import AuthReducer from "../auth/Auth.reducer";


const createRootReducer = () => {

    const ConfigReducer = (state = {}, action) => state;
    return combineReducers({
        ResponseReducer,
        TasksReducer,
        SettingsReducer,
        IncomesReducer,
        ExpensesReducer,
        AuthReducer,
        ConfigReducer
    });
};
export default createRootReducer;