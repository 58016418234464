import * as React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";
import DrawerRouterContainer from "../drawerRouterContainer/DrawerRouterContainer";
import Settings from "../../scenes/settings/Settings";
import Incomes from "../../scenes/incomes/Incomes";
import Expenses from "../../scenes/expenses/Expenses";
import User from "../../scenes/user/User";
import Tasks from "../../scenes/tasks/Tasks";
import { connect } from 'react-redux';
import connector from './SideBar.connector.js';
import { useEffect } from "react";
import { authorizeTasks, authorizeIncomes, authorizeExpenses, authorizeSettings } from "../../authConfig";
import styles from "./SideBar.module.css";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { Loader } from "@progress/kendo-react-indicators";

const SideBar = (props) => {
  const {
    errors,
    successes,
    loading,
    user,
    popError,
    popSuccess,
    requestGetUser
  } = props;

  useEffect(() => {
    requestGetUser();
  }, [requestGetUser]);

  return (
    <React.Fragment >
      {user ? <HashRouter>
        <DrawerRouterContainer user={user}>
          <Routes>
            <Route path="/*" element={authorizeTasks(user) ? <Tasks /> : <React.Fragment />} />
            <Route path="/bevetelek" element={authorizeIncomes(user) ? <Incomes /> : <React.Fragment />} />
            <Route path="/kiadasok" element={authorizeExpenses(user) ? <Expenses /> : <React.Fragment />} />
            <Route path="/beallitasok" element={authorizeSettings(user) ? <Settings /> : <React.Fragment />} />
            <Route path="/felhasznalo" element={<User />} />
          </Routes>
        </DrawerRouterContainer>
      </HashRouter> : null}
      <NotificationGroup className={styles.notificationGroup}>
        <Fade className={styles.animationContainer}>
          {errors.map(error => {
            return <Notification
              key={error.id}
              className={styles.notification}
              type={{
                style: "error",
                icon: true,
              }}
              closable={true}
              onClose={() => { popError(error.id); }}
            >
              <span>{error.message}</span>
            </Notification>;
          })}
          {successes.map(success => {
            return <Notification
              key={success.id}
              className={styles.notification}
              type={{
                style: "success",
                icon: true,
              }}
              closable={true}
              onClose={() => { popSuccess(success.id); }}
            >
              <span>{success.message}</span>
            </Notification>;
          })}
        </Fade>
      </NotificationGroup>
      {loading && <div className="k-overlay"><Loader type="infinite-spinner" size="large" className={styles.loader}></Loader></div>}
    </React.Fragment >
  );
};
export default connect(connector.mapStateToProps, connector.mapDispatchToProps)(SideBar);