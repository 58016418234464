export const severityLevel = {
    Verbose: 0,
    Information: 1,
    Warning: 2,
    Error: 3,
    Critical: 4
};

export const privileges = {
    None: "(nincs jogosultság)",
    Employee: "Alkalmazott",
    Management: "Menedzsment",
    Director: "Vezetőség",
};

export const expensePermissions = {
    None: "(nincs jogosultság)",
    Read: "Olvasás",
    Write: "Írás/Olvasás",
};

export const stateCategories = {
    New: "Új",
    InProgress: "Folyamatban",
    Waiting: "Parkolópálya",
    Closed: "Lezárt"
};

export const fieldTypes = {
    ShortText: "Rövid szöveg",
    LongText: "Hosszú szöveg",
    Link: "Link",
    Date: "Dátum",
    CheckBox: "Jelölőnégyzet"
};