/* eslint-disable import/no-anonymous-default-export */
import * as actionTypes from "./Tasks.action.types";

export const initialState = {
    newTaskTypes: [],
    tasks: [],
    task: null,
    taskType: null,
    isFollow: false,
    history: [],
    attachments: [],
    attachmentLink: null,
    allUsers: null,
    usersByMinPrivilege: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actionTypes.REQUEST_GET_TASKS: {
            return {
                ...state,
                tasks: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_NEW_TASK_TYPES: {
            return {
                ...state,
                newTaskTypes: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_ALL_USERS: {
            return {
                ...state,
                allUsers: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_USERS_BY_MIN_PRIVILEGE: {
            return {
                ...state,
                usersByMinPrivilege: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_TASK_TYPE: {
            return {
                ...state,
                taskType: action.payload.data
            };
        }
        case actionTypes.REQUEST_CREATE_TASK: {
            return {
                ...state,
                task: action.payload.data,
                taskType: action.payload.data.taskType
            };
        }
        case actionTypes.REQUEST_UPDATE_TASK: {
            return {
                ...state,
                task: { ...state.task, ...action.payload.data.task, rowVersion: action.payload.data.rowVersion }
            };
        }
        case actionTypes.REQUEST_GET_TASK: {
            return {
                ...state,
                task: action.payload.data,
                taskType: action.payload.data.taskType
            };
        }
        case actionTypes.REQUEST_GET_TASK_ISFOLLOW: {
            return {
                ...state,
                isFollow: action.payload.data.follow
            };
        }
        case actionTypes.REQUEST_SET_TASK_FOLLOW: {
            return {
                ...state,
                isFollow: true
            };
        }
        case actionTypes.REQUEST_SET_TASK_UNFOLLOW: {
            return {
                ...state,
                isFollow: false
            };
        }
        case actionTypes.REQUEST_GET_TASK_HISTORY: {
            return {
                ...state,
                history: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_TASK_ATTACHMENTS: {
            return {
                ...state,
                attachments: action.payload.data
            };
        }
        case actionTypes.REQUEST_GET_TASK_ATTACHMENT_LINK: {
            return {
                ...state,
                attachmentLink: action.payload.data
            };
        }
        case actionTypes.CLEAR_TASK: {
            return {
                ...state,
                task: null,
                taskType: null
            };
        }
        case actionTypes.CLEAR_TASK_ATTACHMENT_LINK: {
            return {
                ...state,
                attachmentLink: null
            };
        }
        default:
            return state;
    }
}
