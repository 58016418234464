import React from "react";
import { severityLevel } from '../../Enums';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { Button } from "@progress/kendo-react-buttons";

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const appInsights = useAppInsightsContext();

    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
                appInsights.trackException({ exception: e, severityLevel: severityLevel.Error });
                appInsights.trackTrace({ severityLevel: severityLevel.Error, message: "Login failed" }, { exception: e });
            });
        }
    };
    return (
        <Button
            style={{
                width: "150px"
            }}
            size={"large"}
            themeColor="primary"
            onClick={() => handleLogin("redirect")}
        >
            Bejelentkezés
        </Button>
    );
};