import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-react-layout";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import { authorizeExpenses, authorizeIncomes, authorizeSettings, authorizeTasks } from "../../authConfig";
import styles from "./DrawerRouterContainer.module.css";

const DrawerRouterContainer = (props) => {
  const { user } = props;
  const [expanded, setExpanded] = useState(true);
  const { instance, accounts } = useMsal();

  const navigate = useNavigate();
  const location = useLocation();

  const items = [
    {
      text: "",
      icon: "fa-solid fa-bars",
      onClick: () => setExpanded(!expanded),
      authorize: () => true
    },
    {
      text: "Feladatok",
      icon: "fa-solid fa-list-check",
      route: "/",
      selected: true,
      authorize: authorizeTasks
    },
    {
      text: "Bevételek",
      icon: "fa-solid fa-arrow-trend-up",
      route: "/bevetelek",
      authorize: authorizeIncomes
    },
    {
      text: "Kiadások",
      icon: "fa-solid fa-arrow-trend-down",
      route: "/kiadasok",
      authorize: authorizeExpenses
    },
    {
      text: "Beállítások",
      icon: "fa-solid fa-gear",
      route: "/beallitasok",
      authorize: authorizeSettings,
      breakBefore: true // There is always break before the settings menu even when we don't show the menu
    },
    {
      separator: true,
      authorize: () => true
    },
    {
      text: user ? user.name : "Felhasználó",
      icon: "fa-solid fa-user",
      route: "/felhasznalo",
      authorize: () => true
    },
    {
      text: "Kijelentkezés",
      icon: "fa-solid fa-right-from-bracket",
      onClick: () => handleLogout(),
      authorize: () => true
    }
  ];

  const itemRender = (props) => {
    // Don't pass down the authorize and breakBefore properties
    const { authorize, breakBefore, ...childProps } = props;
    // Create the break before the appropriate element even if we don't show the element itself
    return (
      <>
        {breakBefore ? <div style={{ marginTop: 'auto' }} /> : null}
        {props.authorize(user) ?
          (<DrawerItem {...childProps}>
            <span className={styles.itemIcon + " " + props.icon} />
            <span className={styles.itemText}>{props.text}</span>
          </DrawerItem>) : null}
      </>
    );
  };

  const onSelect = (e) => {
    if (e.itemTarget.props.text === selectedItem)
      setExpanded(!expanded);
    else
      navigate(e.itemTarget.props.route);
  };

  const setSelectedItem = (pathName) => {
    let currentPath = items.find((item) => item.route === pathName);
    if (!currentPath)
      currentPath = items[0];
    if (currentPath.text) {
      return currentPath.text;
    }
  };
  const handleLogout = () => {
    instance.logoutRedirect({
      account: accounts[0],
      postLogoutRedirectUri: "/"
    });
  };

  let selectedItem = setSelectedItem(location.pathname);
  return (
    <div>
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css"
      />
      <div>
        {/* <Button className={styles.drawerMenu} iconClass="fa-solid fa-lg fa-bars" fillMode="flat" onClick={() => setExpanded(!expanded)} /> */}
        {/* {expanded ? <span className={styles.menuText}>SP</span> : null} */}
        {/* <img className={styles.logo} width="20px" src={logo}></img> */}
      </div>
      <Drawer
        expanded={expanded}
        position={"start"}
        mode={"push"}
        mini={true}
        miniWidth={48}
        items={items.map((item) => ({
          ...item,
          selected: item.text === selectedItem,
        }))}
        item={itemRender}
        onSelect={onSelect}
        className={styles.container}
      >
        <DrawerContent className={styles.drawerContent}>
          {props.children}
        </DrawerContent>
      </Drawer>
    </div>
  );
};
export default DrawerRouterContainer;