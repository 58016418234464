import React, { useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import styles from "./Settings.module.css";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import { MasterDataDialogs } from "./MasterDataDialogs";

export const MasterDataSettings = (props) => {
    const {
        companies,
        costCenters,
    } = props;

    const [selectedMasterDataTab, setSelectedMasterDataTab] = useState(0);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dialogData, setDialogData] = useState();
    const [dialogType, setDialogType] = useState("");

    const closeDialog = () => setDialogVisible(false);

    const renderCompanyCell = (props) => (
        <td className={styles.parentColumn}>
            <Button className={styles.editButton} fillMode="flat" iconClass="fa-solid fa-pen-to-square" themeColor="primary" onClick={() => {
                setDialogData(props.dataItem);
                setDialogType("updateCompany");
                setDialogVisible(true);
            }} />
            <span>{props.dataItem[props.field]}</span>
        </td>
    );

    const renderParkingItem = (props) => (
        <div>
            <Button className={styles.editButton} fillMode="flat" iconClass="fa-solid fa-pen-to-square" themeColor="primary" onClick={() => {
                setDialogData(props.dataItem);
                setDialogType("updateParking");
                setDialogVisible(true);
            }} />
            <span>{props.dataItem.name}</span>
        </div>
    );

    const renderParkingsCell = (props) => (
        <td className={styles.childColumn}>
            <ListView className={styles.childList} data={props.dataItem[props.field]} item={renderParkingItem} />
            <Button className={styles.childAddButton} fillMode="flat" iconClass="fa-solid fa-plus" themeColor="primary" onClick={() => {
                setDialogData(props.dataItem);
                setDialogType("addParking");
                setDialogVisible(true);
            }} />
        </td>
    );

    const renderCostCenterCell = (props) => (
        <td className={styles.parentColumn}>
            <Button className={styles.editButton} fillMode="flat" iconClass="fa-solid fa-pen-to-square" themeColor="primary" onClick={() => {
                setDialogData(props.dataItem);
                setDialogType("updateCostCenter");
                setDialogVisible(true);
            }} />
            <span>{props.dataItem[props.field]}</span>
        </td>
    );

    const renderExpenseTypeItem = (props) => (
        <div>
            <Button className={styles.editButton} fillMode="flat" iconClass="fa-solid fa-pen-to-square" themeColor="primary" onClick={() => {
                setDialogData(props.dataItem);
                setDialogType("updateExpenseType");
                setDialogVisible(true);
            }} />
            <span>{props.dataItem.name}</span>
        </div>
    );

    const renderExpenseTypesCell = (props) => (
        <td className={styles.childColumn}>
            <ListView className={styles.childList} data={props.dataItem[props.field]} item={renderExpenseTypeItem} />
            <Button className={styles.childAddButton} fillMode="flat" iconClass="fa-solid fa-plus" themeColor="primary" onClick={() => {
                setDialogData(props.dataItem);
                setDialogType("addExpenseType");
                setDialogVisible(true);
            }} />
        </td>
    );

    return (
        <React.Fragment>
            <TabStrip selected={selectedMasterDataTab} onSelect={(e) => setSelectedMasterDataTab(e.selected)}>
                <TabStripTab title="Cégek és parkolók">
                    <div className={styles.tableContainer}>
                        <Button
                            className={styles.addButton}
                            themeColor={"primary"}
                            onClick={() => {
                                setDialogType("addCompany");
                                setDialogVisible(true);
                            }}
                        >
                            Új cég
                        </Button>
                        <Grid
                            data={companies}
                            resizable={true}
                            scrollable="scrollable"
                        >
                            <GridColumn width={300} field="name" title="Cég" cell={(props) => renderCompanyCell(props)} />
                            <GridColumn width={300} field="parkings" title="Parkoló" cell={(props) => renderParkingsCell(props)} />
                        </Grid>
                    </div>
                </TabStripTab>
                <TabStripTab title="Költséghelyek és költségtípusok">
                    <div className={styles.tableContainer}>
                        <Button
                            className={styles.addButton}
                            themeColor={"primary"}
                            onClick={() => {
                                setDialogType("addCostCenter");
                                setDialogVisible(true);
                            }}
                        >
                            Új költséghely
                        </Button>
                        <Grid
                            data={costCenters}
                            resizable={true}
                            scrollable="scrollable"
                        >
                            <GridColumn width={300} field="name" title="Költséghely" cell={(props) => renderCostCenterCell(props)} />
                            <GridColumn width={300} field="expenseTypes" title="Költségtípus" cell={(props) => renderExpenseTypesCell(props)} />
                        </Grid>
                    </div>
                </TabStripTab>
            </TabStrip>
            {dialogVisible ? <MasterDataDialogs dialogType={dialogType} dialogData={dialogData} onCloseDialog={closeDialog} {...props} /> : null}
        </React.Fragment>
    );
};
