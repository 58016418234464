import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import sceneStyles from "../Scenes.module.css";


export const MasterDataDialogs = (props) => {
    const {
        companies,
        costCenters,
        requestGetCompanies,
        requestAddCompany,
        requestAddParking,
        requestUpdateCompany,
        requestUpdateParking,
        requestGetCostCenters,
        requestAddCostCenter,
        requestAddExpenseType,
        requestUpdateCostCenter,
        requestUpdateExpenseType,
        onCloseDialog,
        dialogType,
        dialogData
    } = props;

    const dialogs = {
        "": () => { },
        "addCompany": () => {
            return <Dialog width={500} className={sceneStyles.dialogTitle} title={"Cég hozzáadása"} onClose={onCloseDialog}>
                <Form
                    onSubmit={(values, e) => {
                        requestAddCompany(values, requestGetCompanies);
                        onCloseDialog();
                    }}
                    initialValues={{
                        name: ""
                    }}
                    render={(formProps) => (
                        <FormElement className={sceneStyles.dialogContainer}>
                            <div className={sceneStyles.dialogForm}>
                                <Field
                                    name="name"
                                    label="Név"
                                    required={true}
                                    validator={(value) => value.length > 0 ? "" : "Cég neve üres"}
                                    component={Input}
                                />
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Hozzáadás
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>;
        },
        "updateCompany": () => {
            return <Dialog width={500} className={sceneStyles.dialogTitle} title={`${dialogData.name} módosítása`} onClose={onCloseDialog}>
                <Form
                    onSubmit={(values, e) => {
                        requestUpdateCompany(dialogData.id, values, requestGetCompanies);
                        onCloseDialog();
                    }}
                    initialValues={{
                        name: dialogData.name
                    }}
                    render={(formProps) => (
                        <FormElement className={sceneStyles.dialogContainer}>
                            <div className={sceneStyles.dialogForm}>
                                <Field
                                    name="name"
                                    label="Név"
                                    required={true}
                                    validator={(value) => value.length > 0 ? "" : "Cég neve üres"}
                                    component={Input}
                                />
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Módosítás
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>;
        },
        "addParking": () => {
            return <Dialog width={500} className={sceneStyles.dialogTitle} title={`Parkoló hozzáadása a ${dialogData.name} céghez`} onClose={onCloseDialog}>
                <Form
                    onSubmit={(values, e) => {
                        const parking = { name: values.name, companyId: dialogData.id, commonExpensesCount: values.commonExpensesCount };
                        requestAddParking(parking, requestGetCompanies);
                        onCloseDialog();
                    }}
                    initialValues={{
                        name: "",
                        commonExpensesCount: false
                    }}
                    render={(formProps) => (
                        <FormElement className={sceneStyles.dialogContainer}>
                            <div className={sceneStyles.dialogForm}>
                                <Field
                                    name="name"
                                    label="Név"
                                    required={true}
                                    validator={(value) => value.length > 0 ? "" : "Parkoló neve üres"}
                                    component={Input}
                                />
                                <Field
                                    name="commonExpensesCount"
                                    label="Közös költségek számítanak"
                                    labelPlacement={"after"}
                                    component={Checkbox}
                                />
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Hozzáadás
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>;
        },
        "updateParking": () => {
            const companyNames = companies.map(c => c.name);
            return <Dialog width={500} className={sceneStyles.dialogTitle} title={`${dialogData.name} módosítása`} onClose={onCloseDialog}>
                <Form
                    onSubmit={(values, e) => {
                        const companyId = companies.find(c => c.name === values.companyName).id;
                        const parkingUpdate = { name: values.name, companyId: companyId, commonExpensesCount: values.commonExpensesCount };
                        requestUpdateParking(dialogData.id, parkingUpdate, requestGetCompanies);
                        onCloseDialog();
                    }}
                    initialValues={{
                        companyName: companies.find(c => c.id === dialogData.companyId).name,
                        name: dialogData.name,
                        commonExpensesCount: dialogData.commonExpensesCount
                    }}
                    render={(formProps) => (
                        <FormElement className={sceneStyles.dialogContainer}>
                            <div className={sceneStyles.dialogForm}>
                                <Field
                                    name="companyName"
                                    label="Cég"
                                    data={companyNames}
                                    validator={(value) => companyNames.includes(value) ? "" : "Nem megfelelő cég"}
                                    required={true}
                                    component={DropDownList}
                                />
                                <Field
                                    name="name"
                                    label="Név"
                                    required={true}
                                    validator={(value) => value.length > 0 ? "" : "Parkoló neve üres"}
                                    component={Input}
                                />
                                <Field
                                    name="commonExpensesCount"
                                    label="Közös költségek számítanak"
                                    labelPlacement={"after"}
                                    component={Checkbox}
                                />
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Módosítás
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>;
        },
        "addCostCenter": () => {
            return <Dialog width={500} className={sceneStyles.dialogTitle} title={"Költséghely hozzáadása"} onClose={onCloseDialog}>
                <Form
                    onSubmit={(values, e) => {
                        requestAddCostCenter(values, requestGetCostCenters);
                        onCloseDialog();
                    }}
                    initialValues={{
                        name: ""
                    }}
                    render={(formProps) => (
                        <FormElement className={sceneStyles.dialogContainer}>
                            <div className={sceneStyles.dialogForm}>
                                <Field
                                    name="name"
                                    label="Név"
                                    required={true}
                                    validator={(value) => value.length > 0 ? "" : "Költséghely neve üres"}
                                    component={Input}
                                />
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Hozzáadás
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>;
        },
        "updateCostCenter": () => {
            return <Dialog width={500} className={sceneStyles.dialogTitle} title={`${dialogData.name} módosítása`} onClose={onCloseDialog}>
                <Form
                    onSubmit={(values, e) => {
                        requestUpdateCostCenter(dialogData.id, values, requestGetCostCenters);
                        onCloseDialog();
                    }}
                    initialValues={{
                        name: dialogData.name
                    }}
                    render={(formProps) => (
                        <FormElement className={sceneStyles.dialogContainer}>
                            <div className={sceneStyles.dialogForm}>
                                <Field
                                    name="name"
                                    label="Név"
                                    required={true}
                                    validator={(value) => value.length > 0 ? "" : "Költséghely neve üres"}
                                    component={Input}
                                />
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Módosítás
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>;
        },
        "addExpenseType": () => {
            return <Dialog width={500} className={sceneStyles.dialogTitle} title={`Költségtípus hozzáadása a ${dialogData.name} költséghelyhez`} onClose={onCloseDialog}>
                <Form
                    onSubmit={(values, e) => {
                        const expenseType = { name: values.name, costCenterId: dialogData.id };
                        requestAddExpenseType(expenseType, requestGetCostCenters);
                        onCloseDialog();
                    }}
                    initialValues={{
                        name: ""
                    }}
                    render={(formProps) => (
                        <FormElement className={sceneStyles.dialogContainer}>
                            <div className={sceneStyles.dialogForm}>
                                <Field
                                    name="name"
                                    label="Név"
                                    required={true}
                                    validator={(value) => value.length > 0 ? "" : "Költségtípus neve üres"}
                                    component={Input}
                                />
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Hozzáadás
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>;
        },
        "updateExpenseType": () => {
            const costCenterNames = costCenters.map(c => c.name);
            return <Dialog width={500} className={sceneStyles.dialogTitle} title={`${dialogData.name} módosítása`} onClose={onCloseDialog}>
                <Form
                    onSubmit={(values, e) => {
                        const costCenterId = costCenters.find(c => c.name === values.costCenterName).id;
                        const expenseTypeUpdate = { name: values.name, costCenterId: costCenterId };
                        requestUpdateExpenseType(dialogData.id, expenseTypeUpdate, requestGetCostCenters);
                        onCloseDialog();
                    }}
                    initialValues={{
                        costCenterName: costCenters.find(c => c.id === dialogData.costCenterId).name,
                        name: dialogData.name,
                    }}
                    render={(formProps) => (
                        <FormElement className={sceneStyles.dialogContainer}>
                            <div className={sceneStyles.dialogForm}>
                                <Field
                                    name="costCenterName"
                                    label="Költséghely"
                                    data={costCenterNames}
                                    validator={(value) => costCenterNames.includes(value) ? "" : "Nem megfelelő költséghely"}
                                    required={true}
                                    component={DropDownList}
                                />
                                <Field
                                    name="name"
                                    label="Név"
                                    required={true}
                                    validator={(value) => value.length > 0 ? "" : "Költségtípus neve üres"}
                                    component={Input}
                                />
                                <div className={sceneStyles.dialogActionsBar}>
                                    <Button
                                        themeColor={"primary"}
                                        type={"submit"}
                                        disabled={!formProps.valid || !formProps.allowSubmit}
                                    >
                                        Módosítás
                                    </Button>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </Dialog>;
        }
    };

    return dialogs[dialogType]();
};