import React, { useState, useEffect, useRef, useMemo, useLayoutEffect, Fragment } from "react";
import styles from "./TaskWindow.module.css";
import sceneStyles from "../Scenes.module.css";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Typography } from "@progress/kendo-react-common";
import { convertToDashedDateString } from '../../Utils';
import { Button } from "@progress/kendo-react-buttons";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { History } from "./History";
import { Attachments } from "./Attachments";
import CustomFieldsForm from "./CustomFieldsForm";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const urlRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export const TaskWindow = (props) => {
    const {
        isNewTask,
        task,
        isFollow,
        taskType,
        history,
        attachments,
        attachmentLink,
        allUsers,
        usersByMinPrivilege,
        requestGetUsersByMinPrivilege,
        requestRemoveTask,
        requestCreateTask,
        requestUpdateTask,
        requestSetTaskFollow,
        requestSetTaskUnfollow,
        requestGetTaskHistory,
        requestGetTaskAttachmentLink,
        requestGetTaskAttachments,
        requestRemoveTaskAttachment,
        requestAddTaskAttachment,
        clearAttachmentLink,
        handleCloseWindow,
        handleUpdateWindow
    } = props;

    const [selectedTab, setSelectedTab] = useState(0);

    const [newTaskFollow, setNewTaskFollow] = useState(true);
    const [title, setTitle] = useState("");
    const [dueDate, setDueDate] = useState(new Date());
    const [assignees, setAssignees] = useState([]);
    const [state, setState] = useState();

    const [description, setDescription] = useState("");
    const [checkValidity, setCheckValidity] = useState(false);
    const [customFieldsData, setCustomFieldsData] = useState({});
    const [customFieldValidities, setCustomFieldValidities] = useState({});

    const customFieldForm = useRef(null);

    const tabStrip = useRef(null);
    const [tabWidth, setTabWidth] = useState(0);
    const [tabHeight, setTabHeight] = useState(0);

    const [removeTaskDialogData, setRemoveTaskDialogData] = useState({ visible: false, task: null, taskType: null });

    // Save custom fields if tab changed
    useEffect(() => {
        if (customFieldForm.current) {
            setCustomFieldsData(customFieldForm.current.getCurrentCustomFieldsData());
        }
    }, [selectedTab]);

    useEffect(() => {
        setCustomFieldValidities(taskType.customFields.reduce((acc, field) => {
            acc[field.id] = true;
            return acc;
        }, {}));

        if (!isNewTask) {
            loadTaskData();
        } else {
            setState(taskType.states.find(s => s.category === "New" && s.isPrimary).name);
            setCustomFieldsData(taskType.customFields.reduce((acc, field) => {
                if (field.type === 'CheckBox')
                    acc[field.id] = "Nincs jelölve";
                else
                    acc[field.id] = null;
                return acc;
            }, {}));
        }
    }, [isNewTask, task]);

    const updateTabDimensions = () => {
        if (tabStrip.current) {
            setTabWidth(tabStrip.current._element.clientWidth);
            setTabHeight(tabStrip.current._element.clientHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateTabDimensions);

        return () => {
            window.removeEventListener('resize', updateTabDimensions);
        };
    }, []);

    useLayoutEffect(() => {
        updateTabDimensions();
    }, [tabStrip.current]);

    useEffect(() => {
        requestGetUsersByMinPrivilege(taskType.privilege);
    }, [requestGetUsersByMinPrivilege, taskType.privilege]);

    const loadTaskData = () => {
        setTitle(task.title);
        setDescription(task.description);
        setDueDate(task.dueDate);
        setAssignees(task.assignees.map(assignee => allUsers.find(u => u.id === assignee).name));
        setState(taskType.states.find(s => s.id === task.statusId).name);
        setCustomFieldsData(task.customFieldsData.reduce((acc, field) => {
            acc[field.id] = field.value;
            return acc;
        }, {}));
        setCheckValidity(true);
    };

    const handleSave = (onSuccess) => {
        setCheckValidity(true);

        let currentCustomFieldsData = {};
        if (customFieldForm.current) {
            currentCustomFieldsData = customFieldForm.current.getCurrentCustomFieldsData();
        } else {
            currentCustomFieldsData = customFieldsData;
        }

        let validities = {};
        taskType.customFields.forEach((field, idx) => {

            const value = currentCustomFieldsData[field.id];

            // Check validities
            if (field.type === 'ShortText' || field.type === 'LongText') {
                validities[field.id] = !field.mandatory || Boolean(value);
            } else if (field.type === 'Link') {
                validities[field.id] = value?.match(urlRegex) !== null || (!Boolean(value) && !field.mandatory);
            } else if (field.type === 'CheckBox') {
                validities[field.id] = true;
            } else if (field.type === 'Date') {
                validities[field.id] = !field.mandatory || Boolean(value);
            }
        });

        setCustomFieldValidities(validities);

        if (Object.values(validities).every(v => v === true) && assignees.length > 0 && title.trim().length > 0 && description.trim().length > 0) {
            if (isNewTask) {
                requestCreateTask({
                    taskTypeId: taskType.typeId,
                    title: title,
                    assignees: allUsers.filter(u => assignees.includes(u.name)).map(u => u.id),
                    description: description,
                    dueDate: convertToDashedDateString(dueDate),
                    customFieldsData: taskType.customFields.map((field) => ({ id: field.id, value: currentCustomFieldsData[field.id] === '' ? null : currentCustomFieldsData[field.id] })),
                    isFollow: newTaskFollow
                }, onSuccess);
            } else {
                requestUpdateTask({
                    id: task.id,
                    title: title,
                    assignees: allUsers.filter(u => assignees.includes(u.name)).map(u => u.id),
                    statusId: taskType.states.find(s => s.name === state).id,
                    description: description,
                    dueDate: convertToDashedDateString(dueDate),
                    customFieldsData: taskType.customFields.map((field) => ({ id: field.id, value: currentCustomFieldsData[field.id] === '' ? null : currentCustomFieldsData[field.id] })),
                    rowVersion: task.rowVersion
                }, onSuccess);
            }
        }
    };

    const closeTaskRemoveDialog = () => setRemoveTaskDialogData({ visible: false, task: null });

    const windowTitleBar = useMemo(() => (
        <div className={`${styles.windowTitleBar}`}>
            <Typography.p className={styles.titleBarText}>
                {`${taskType.name} (${taskType.group})`}
            </Typography.p>
            <div className={styles.titleBarActions}>
                <Button fillMode="flat" iconClass="fa-solid fa-lg fa-trash trash-icon" onClick={() => setRemoveTaskDialogData({ visible: true, task: task })} />
                <Button
                    onClick={() => handleSave((response) => {
                        handleUpdateWindow(isNewTask ? response : task);
                    })}
                >Mentés
                </Button>
                <Button onClick={() => handleSave(() => handleCloseWindow(true))}>Mentés & bezárás</Button>
                <Button fillMode="flat" iconClass="fa-solid fa-lg fa-eye" themeColor={(isNewTask ? newTaskFollow : isFollow) ? 'primary' : 'base'}
                    onClick={(e) => {
                        if (isNewTask)
                            setNewTaskFollow(!newTaskFollow);
                        else
                            isFollow ? requestSetTaskUnfollow(task.id) : requestSetTaskFollow(task.id);
                    }}
                    onMouseDown={e => e.preventDefault()}
                />
                <Button fillMode="flat" iconClass="fa-solid fa-lg fa-x" onClick={handleCloseWindow} />
            </div>
        </div>), [newTaskFollow, isFollow, handleSave, handleUpdateWindow, task]);


    const header = useMemo(() => (
        <div
            className={styles.header}
            style={{ borderLeftColor: taskType.color }}
        >
            {windowTitleBar}
            <div className={styles.headerRow}>
                <span className={styles.idContainer}>
                    <i className={`fa-regular fa-hashtag ${styles.headerIcon}`}></i>
                    {isNewTask ?
                        <i className={`fa-regular fa-hashtag ${styles.headerIcon}`}></i> :
                        <span className={styles.idText}>{task.id}</span>}
                </span>
                <Input className={styles.input} placeholder="Feladat neve..." value={title} onChange={e => setTitle(e.value)} valid={!checkValidity || title.trim().length > 0} autoFocus />
            </div>
            <div className={styles.headerRow}>
                <div className={styles.dateContainer} style={{ flex: 1 }}>
                    <i className={`fa-regular fa-clock ${styles.headerIcon}`}></i>
                    <DatePicker value={dueDate} onChange={(e) => {
                        setDueDate(e.value);
                    }} />
                </div>
                <MultiSelect style={{ flex: 2 }} data={usersByMinPrivilege?.map(u => u.name) ?? []} value={assignees} onChange={(e) => setAssignees([...e.value])} placeholder="Felelősök..." valid={!checkValidity || assignees.length > 0} />
                <DropDownList style={{ flex: 2 }} data={taskType.states.map(s => s.name)} value={state} onChange={e => setState(e.value)} disabled={isNewTask} />
            </div>
        </div>
    ), [title, dueDate, assignees, state, windowTitleBar, checkValidity, task]);

    return (
        <Fragment>
            <div className={`k-overlay`} />
            <div
                className={`k-window k-dialog ${styles.modal}`}
            >
                {header}
                <div className={styles.body}>
                    <TabStrip selected={selectedTab} onSelect={(e) => setSelectedTab(e.selected)} style={{ height: '100%' }} ref={tabStrip}>
                        <TabStripTab title="Adatok" contentClassName={styles.dataTab} >
                            <div
                                className={styles.dataTab}
                                style={{
                                    width: `${tabWidth - 36}px`,
                                    height: `${tabHeight - 90}px`
                                }}
                            >
                                <Typography.p>
                                    Létrehozás dátuma: {isNewTask ? new Date().toLocaleDateString() : task.creationDate.toLocaleDateString()}
                                </Typography.p>
                                <div className={styles.dataFields}>
                                    <div style={{ flex: 2 }}>
                                        <TextArea
                                            className={`${styles.descriptionArea} ${styles.input} fill-height`}
                                            value={description}
                                            onChange={e => setDescription(e.value)}
                                            autoSize={true}
                                            placeholder="Feladat részletes leírása..."
                                            fillMode='outline'
                                            valid={!checkValidity || description.trim().length > 0}
                                        />
                                    </div>
                                    <div style={{ flex: 3 }} className={styles.customFields}>
                                        <CustomFieldsForm
                                            ref={customFieldForm}
                                            customFields={taskType.customFields}
                                            customFieldsData={customFieldsData}
                                            customFieldValidities={customFieldValidities}
                                            checkValidity={checkValidity}
                                        />
                                    </div>
                                </div>
                            </div>
                        </TabStripTab>
                        <TabStripTab title="Állapot történet">
                            <div
                                style={{
                                    width: `${tabWidth - 36}px`,
                                    height: `${tabHeight - 90}px`,
                                    overflowY: 'auto'
                                }}
                            >
                                <History
                                    creatorName={allUsers.find(u => u.id === task?.creatorUser)?.name}
                                    creationDate={task?.creationDate}
                                    isNewTask={isNewTask}
                                    history={history}
                                />
                            </div>
                        </TabStripTab>
                        <TabStripTab title="Csatolmányok">
                            <div
                                style={{
                                    width: `${tabWidth - 36}px`,
                                    height: `${tabHeight - 90}px`,
                                    overflowY: 'auto'
                                }}
                            >
                                <Attachments
                                    isNewTask={isNewTask}
                                    task={task}
                                    attachments={attachments}
                                    attachmentLink={attachmentLink}
                                    requestGetTaskAttachmentLink={requestGetTaskAttachmentLink}
                                    requestGetTaskAttachments={requestGetTaskAttachments}
                                    requestRemoveTaskAttachment={requestRemoveTaskAttachment}
                                    requestAddTaskAttachment={requestAddTaskAttachment}
                                    clearAttachmentLink={clearAttachmentLink}
                                    requestGetTaskHistory={requestGetTaskHistory}
                                />
                            </div>
                        </TabStripTab>
                    </TabStrip>
                </div>
                {removeTaskDialogData.visible && (
                    <Dialog
                        width={400}
                        title={"Megerősítés"}
                        onClose={closeTaskRemoveDialog}
                        autoFocus={true}
                    >
                        <Typography.p>{"Biztosan törli a feladatot?"} </Typography.p>
                        <DialogActionsBar>
                            <div className={sceneStyles.dialogActionsBar}>
                                <Button
                                    onClick={closeTaskRemoveDialog}
                                >
                                    Nem
                                </Button>
                                <Button
                                    themeColor="primary"
                                    onClick={() => {
                                        requestRemoveTask(removeTaskDialogData.task.id, () => handleCloseWindow(true));
                                        closeTaskRemoveDialog();
                                    }}
                                >
                                    Igen
                                </Button>
                            </div>
                        </DialogActionsBar>
                    </Dialog>
                )}
            </div>
        </Fragment>
    );
};